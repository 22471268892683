import { SFColumnType } from "../../constants/Common/Constants";
import { ValueJson } from "../models/Common/QueryAttributeJM";

export declare type TextAlign =
    /**  Defines Left alignment */
    'Left' |
    /**  Defines Right alignment */
    'Right' |
    /**  Defines Center alignment */
    'Center' |
    /**  Defines Justify alignment */
    'Justify';

export interface RAFGridColumnProps<T> {
    field: keyof T,
    type?: SFColumnType,
    headerText?: string,
    visible?: boolean,
    //template?: React.ReactElement,
    template?: any,
    width?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
    valueAccessor?: any;
    //textAlign?: TextAlign;
    //headerTextAlign?: TextAlign;
    isPrimaryKey?: boolean;
    isEditable?: boolean;
    valueJson?: ValueJson[];
}

export const RAFGridColumnDefaultProps = {
    type: SFColumnType.string,
    visible: true,
    minWidth: 120,
    maxWidth: 500,
    isPrimaryKey: false
};

export interface GridColumnTemplates {
    key?: string,
    value?: string,
    cols?: string[];
}

export interface GridColumnWidth {
    field?: string,
    width?: string;
}

export interface GridColumnHeader {
    field?: string,
    value?: string;
}


