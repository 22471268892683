import { PropsWithChildren } from 'react';
import { RAFGridColumnDefaultProps, RAFGridColumnProps } from './RAFSFGridUtils';


function RAFGridColumn<T>(
    { }: PropsWithChildren<RAFGridColumnProps<T>>,
) {
    return null;
}
RAFGridColumn.defaultProps = RAFGridColumnDefaultProps;
export default RAFGridColumn;




