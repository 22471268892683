import { Browser, createElement, debounce } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { DatePicker, DateRangePicker, PresetsModel } from "@syncfusion/ej2-react-calendars";
import {
  ComboBox,
  DropDownList,
  FilteringEventArgs
} from "@syncfusion/ej2-react-dropdowns";
import {
  Action,
  ActionEventArgs,
  Column,
  ColumnDirective, ColumnsDirective,
  CommandClickEventArgs,
  CommandColumn,
  CommandModel,
  DataStateChangeEventArgs,
  Edit,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  FilterSettingsModel,
  Freeze,
  Grid,
  GridComponent,
  GridModel,
  Group,
  GroupSettingsModel,
  IFilterMUI,
  IFilterUI,
  Inject,
  Page,
  PageSettingsModel,
  PdfExport,
  PdfExportProperties,
  PdfHeaderQueryCellInfoEventArgs,
  PdfQueryCellInfoEventArgs,
  PredicateModel,
  RecordClickEventArgs,
  Resize,
  SelectionSettingsModel,
  Sort,
  SortSettingsModel,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import { TextBox } from "@syncfusion/ej2-react-inputs";
import {
  ClickEventArgs,
  MenuEventArgs
} from "@syncfusion/ej2-react-navigations";
import {
  createSpinner,
  hideSpinner,
  showSpinner,
  Tooltip,
  TooltipComponent,
  TooltipEventArgs
} from "@syncfusion/ej2-react-popups";
import moment from "moment";
import * as R from "ramda";
import React, { ReactNode } from "react";
import noRecordIcon from "../../assets/NoRecordIcon.svg";
import { RolePermissionsContext } from "../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { SetGridState, GetGridModelFromGridState } from "../helpers/AppHelper";
import {
  Constants,
  MomentFormats,
  RAFDatePickerViewFormat,
  SFColumnType
} from "../../constants/Common/Constants";
import { getURLPrefixByModuleName } from "../../helpers/Common/URLPrefixByModuleName";
import { hasPermissions } from "../helpers/PermissionHelper";
import {
  formatSFDateOption,
  getCustomFilterBySFGridFilter,
  getCustomFilterBySFGridFilter1,
  getDisplayNameBySFOperator,
  getGridFilterByCustomFilter
} from "../helpers/SFGridUtils";
import { RAFSort } from "../helpers/types";
import {
  convertUTCDateStringToLocalTimezone,
  deepEqual,
  getSFColumnTypeByRAFDatatype,
  isNotNullAndUndefined,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
  truncateAfter
} from "../helpers/utils";
import Aux from "../hoc/Auxiliary/Auxiliary";
import { RAFDataManager, RAFUrlAdaptor } from "../Inputs/RAFUrlAdaptor";
import { RAFDataListAdditionalProps } from "../List/RAFReportDataList";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFDataType, RAFUIType } from "../models/Common/RAFDataType";
import { RAFEntityBase } from "../models/Common/RAFEntityBase";
import { GroupByJM } from "../models/Common/RAFViewRow";
import { RAFEmptyStateProps } from "../Navigation/RAFEmptyState";
import { RAFCustomFilter } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import loaderIcon from "../../assets/LoaderIconCircle.svg";
import {
  GridColumnHeader,
  GridColumnTemplates,
  GridColumnWidth,
  RAFGridColumnProps
} from "./RAFSFGridUtils";
import { getGridColumnTemplateItems } from "../../helpers/ACRAFGridHelperTemplates";

export declare type RafGridFilterType = "Menu" | "Excel";
export declare type ExportType = "Pdf" | "Csv" | "Excel";

interface IProps {
  currentSort?: RAFSort;
  currentFilter?: RAFCustomFilter;
  pageNumber?: number;
  pageSize?: number;
  data?: any;
  actions?: any;

  gridId?: string;
  entityType?: typeof RAFEntityBase;
  isRemote?: boolean;
  rowClick?: (rowData: Object) => void;
  editClick?: (rowData: Object) => void;
  customClick?: (rowData: Object) => void;
  customActionClick?: (rowData: Object) => void;
  allowSelection?: boolean;
  isRelatedSection?: boolean;
  filterType?: RafGridFilterType;
  actionButtons?: CommandModel[];
  deleteClicked?: (selectedRows: Object[]) => void;
  showToolbar?: boolean;
  allowEditing?: boolean;
  showEditColumn?: boolean;
  allowFiltering?: boolean;
  rowSelected?: (selectedRows: Object[]) => void;
  hasClientTerms?: boolean;
  gridTemplates?: GridColumnTemplates[];
  gridColumnWidth?: GridColumnWidth[];
  gridColumnHeader?: GridColumnHeader[];
  showEdit?: boolean;
  rowTemplate?: any;
  groupByColumns?: GroupByJM[];
  emptyStateProps?: RAFEmptyStateProps;
  disableFirstColumnTemplate?: boolean;
  sortBy?: RAFSort;
  actionButtonClicked?: (id: string, selectedRow: Object) => void;
  pageSizes?: number[];
  moduleName?: string;
  idField?: string;

  showHeader?: boolean;
  hasOtherViews?: boolean;
  fields?: QueryAttributeJM[];
  templateHelpers?: any;
  checkPermissionEdit?: string[];
  checkPermissionSelection?: string[];
  frozenColumns?: number;
  filterBarVisibile?: boolean;
  created?: (grid: Grid) => void;

  cssClass?: string;
  isDynamic?: boolean;
  isAdaptive?: boolean;
  mobileColCount?: number;
  children?: ReactNode;
}

interface IState {
  data?: any;
  filterBarVisibile?: boolean;
}

class RAFGridCC extends React.Component<
  IProps & RAFDataListAdditionalProps,
  IState
> {
  private grid: Grid | null;
  private tooltip: TooltipComponent;
  public selectedRecords: Object[];

  private textboxObj: TextBox;
  private dropDownObj: DropDownList;
  private booleandropDownObj: DropDownList;
  private lookupObject: DropDownList;
  private daterangepickerObj: DateRangePicker;
  private datepickerObj: DatePicker;

  //console.log('RAFGrid1 props=', props);
  //private data;
  private pageNumber;
  private pageSize;
  private currentSort;
  private currentFilter;

  private allowFiltering = true;
  private showHeader = true;
  private isRemote = false;
  private allowSelection = true;
  private isRelatedSection = false;
  private allowEditing = false;
  private showEditColumn = false;
  private hasOtherViews = false;
  private gridTemplates?: GridColumnTemplates[];
  private emptyStateProps?: RAFEmptyStateProps;
  private showToolbar = true;
  private disableFirstColumnTemplate = false;
  private rowTemplate: any;
  private isDynamic = false;
  private isAdaptive = false;
  private mobileColCount = 0;

  private pageSettings: PageSettingsModel = {
    currentPage: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50, 100],
    pageCount: 8
  };
  private sortingOptions: SortSettingsModel = { allowUnsort: true };
  private filterSettings: FilterSettingsModel = {
    showFilterBarStatus: false,
    mode: "Immediate",
    type: "FilterBar",
  };
  private filterOptions: FilterSettingsModel = { type: "Menu" };
  private groupSettings: GroupSettingsModel = {
    showDropArea: false,
    showGroupedColumn: false,
    showToggleButton: false,
    showUngroupButton: false,
  };
  private selectionsettingsModel: SelectionSettingsModel = {
    checkboxOnly: true,
    //persistSelection: true
  };
  private stringOperators: Object[] = [
    { value: "startswith", text: "Starts With" },
    { value: "endswith", text: "Ends With" },
    { value: "contains", text: "Contains" },
    { value: "equal", text: "Equal" },
    { value: "notequal", text: "Not Equal" },
  ];

  private getCommandModel = (): CommandModel[] => {
    let retVal: CommandModel[] = [];
    if (
      isNotNullAndUndefined(this.props.actionButtons) &&
      this.props.actionButtons.length > 0
    ) {
      retVal.push(...this.props.actionButtons);
    }
    //console.log("this.props.actionButtons", this.props.actionButtons)
    return retVal;
  };

  constructor(props: IProps & RAFDataListAdditionalProps) {
    super(props);

    if (props.data) {
      //this.data = props.data.data;
      this.pageNumber = props.data.pageNumber;
      this.pageSize = props.data.pageSize;
      this.currentSort = props.data.currentSort;
      this.currentFilter = props.data.currentFilter;
      this.pageSettings.currentPage = this.pageNumber ?? 1;
      this.pageSettings.pageSize = this.pageSize ?? 10;
      if (this.currentSort && !IsNullOrWhiteSpace(this.currentSort.field)) {
        this.sortingOptions.columns = [
          { field: this.currentSort.field, direction: this.currentSort.order },
        ];
      }

      if (this.currentFilter) {
        this.filterSettings.columns = getGridFilterByCustomFilter(
          this.currentFilter
        );
      }

      this.state = {
        data: { ...props.data.data },
        filterBarVisibile: true,
        // isNotNullAndUndefined(props.filterBarVisibile)
        //   ? props.filterBarVisibile
        //   : false,
      };
    } else {
      this.state = {
        filterBarVisibile: true,
        // isNotNullAndUndefined(props.filterBarVisibile)
        //   ? props.filterBarVisibile
        //   : false,
      };
    }
    //this.setState({ filterBarVisibile: isNotNullAndUndefined(this.props.filterBarVisibile) ? this.props.filterBarVisibile : false })
    this.allowFiltering = props.allowFiltering ?? true;
    this.showHeader = props.showHeader ?? true;
    this.isRemote = props.isRemote ?? true;
    this.allowSelection = props.allowSelection ?? true;
    this.isRelatedSection = props.isRelatedSection ?? false;
    this.allowEditing = props.allowEditing ?? false;
    this.showEditColumn = props.showEditColumn ?? false;
    this.hasOtherViews = props.hasOtherViews ?? false;
    this.gridTemplates = props.gridTemplates ?? [];
    this.emptyStateProps = props.emptyStateProps ?? null;
    this.showToolbar = props.showToolbar ?? true;
    this.disableFirstColumnTemplate = props.disableFirstColumnTemplate ?? false;
    this.rowTemplate = props.rowTemplate ?? null;
    this.isDynamic = props.isDynamic ?? false;
    this.isAdaptive = props.isAdaptive ?? false;
    this.mobileColCount = props.mobileColCount ?? 0;
  }

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    this.toggleFilterBar();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  //const { fetchData, filtering, paging, sorting } = props.actions;
  //const { gridId, moduleName, idField, fields, children, templateHelpers } = props;

  today: Date = new Date(new Date().toDateString());
  weekStart: Date = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  weekEnd: Date = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 7) % 7)
          )
        ).getDate() + 6
      )
    ).toDateString()
  );
  monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  monthEnd: Date = this.today;
  lastStart: Date = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
    ).toDateString()
  );
  lastEnd: Date = this.today;
  yearStart: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() - 365)).toDateString()
  );
  yearEnd: Date = this.today;

  presetDateModel: PresetsModel[] = [
    { label: "This Week", start: this.weekStart, end: this.weekEnd },
    { label: "This Month", start: this.monthStart, end: this.monthEnd },
    { label: "Last Month", start: this.lastStart, end: this.lastEnd },
    { label: "Last Year", start: this.yearStart, end: this.yearEnd },
  ];

  private hideToolbarMenu = () => {
    let toolbar = document.getElementById("toolbar-actions");
    if (isNotNullAndUndefined(toolbar)) {
      toolbar.classList.add("toolbarhidden");
      toolbar.classList.remove("toolbarVisible");
    }
  };

  private hideGridSpinner = () => {
    setTimeout((e) => {
      let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
      if (this.grid && this.grid.element) {
        const gridParentDiv = this.grid.element.closest("#divGridParent");
        if (gridParentDiv) {
          const customSpinner = gridParentDiv.querySelector(
            "#customSpinner"
          ) as HTMLElement;
          if (
            isNotNullAndUndefined(customSpinner) &&
            !IsNullOrWhiteSpace(customSpinner.innerHTML)
          ) {
            spinnerTarget = gridParentDiv.querySelector(
              "#customSpinner"
            ) as HTMLElement;
            customSpinner.classList.add("hidden");
          }
        }
      }
      hideSpinner(spinnerTarget);
      if (this.grid) {
        //grid.hideSpinner();
        let mainElement: HTMLElement = document.documentElement;
        if (this.grid.element) {
          mainElement = this.grid.element.parentElement;
        }
        const eSpinnerPane: HTMLElement =
          mainElement.querySelector(".e-spinner-pane");
        if (isNotNullAndUndefined(eSpinnerPane)) {
          eSpinnerPane.classList.add("hidden");
          eSpinnerPane.classList.remove("e-spin-show");
        }
        const currentViewRecords = this.grid.getCurrentViewRecords();
        if (
          isNotNullAndUndefined(currentViewRecords) &&
          currentViewRecords.length > 0
        ) {
          this.grid.element.classList.remove("hidden");
          //grid.element.classList.add("fade-in");
        } else {
          if (
            isNotNullAndUndefined(this.grid.filterSettings) &&
            isNotNullAndUndefined(this.grid.filterSettings.columns) &&
            this.grid.filterSettings.columns.length > 0
          ) {
            //if condition added to show grid filterbar when filter is applied and also no records found
            this.grid.element.classList.remove("hidden");
          } else {
            //grid.element.classList.add("hidden");
          }
        }
      }
    }, 100);
  };

  private showGridSpinner = () => {
    setTimeout(() => {
      let mainElement: HTMLElement = document.documentElement;
      if (this.grid && this.grid.element) {
        mainElement = this.grid.element.parentElement;
      }
      const eSpinnerPane: HTMLElement =
        mainElement.querySelector(".e-spinner-pane");
      if (isNotNullAndUndefined(eSpinnerPane)) {
        eSpinnerPane.classList.remove("hidden");
        eSpinnerPane.classList.add("e-spin-show");
      }
    }, 100);
  };

  private ConstructFilterMessage = (
    filterColumnsModel: PredicateModel[]
  ): string => {
    let retVal = "";
    if (
      isNotNullAndUndefined(filterColumnsModel) &&
      filterColumnsModel.length > 0
    ) {
      for (var i = 0; i < filterColumnsModel.length; i++) {
        const col: PredicateModel = filterColumnsModel[i];
        let value = col.value;
        const operator: string = col.operator;
        const field: string = col.field;
        let displayName: string = field;
        let matchedAttribute = isNotNullAndUndefined(this.props.fields)
          ? this.props.fields.find((x) => x.PropertyName === field.toString())
          : null;
        //if (isNotNullAndUndefined(state.selectedGridColumns)) {
        //    columnModel = state.selectedGridColumns.find(x => x.field === field);
        //    if (isNotNullAndUndefined(columnModel) && !IsNullOrWhiteSpace(columnModel.field)) {
        //        displayName = columnModel.headerText;
        //    }
        //}

        if (col.type === SFColumnType.date) {
          value = moment(value as Date).format(MomentFormats.DATE);
        }

        // if (matchedAttribute.DataType === SFColumnType.dropdown) {
        //   let selectedValueJson = matchedAttribute.ValueJson;
        //   if (isNotNullAndUndefined(selectedValueJson)) {
        //     let selectedItem = selectedValueJson.find(
        //       (x) => x.Name === value || x.DisplayName === value
        //     );
        //     value = isNotNullAndUndefined(selectedItem)
        //       ? selectedItem.DisplayName
        //       : value;
        //   }
        // }

        if (value === true) {
          value = "Yes";
        }
        if (value === false) {
          value = "No";
        }

        if (isNotNullAndUndefined(value)) {
          if (i === 0) {
            retVal = `${displayName} ${getDisplayNameBySFOperator(
              operator
            )} '${value}'`;
          } else {
            retVal = `${retVal} and ${displayName} ${getDisplayNameBySFOperator(
              operator
            )} '${value}'`;
          }
        }
      }
    }
    return retVal;
  };

  private refreshData = (resetGrid?: boolean) => {
    if (this.grid) {
      if (resetGrid) {
        let model: GridModel = null;
        if (isNotNullAndUndefined(this.props.gridId)) {
          model = GetGridModelFromGridState(this.props.gridId);
          if (isNotNullAndUndefined(model)) {
            model.sortSettings.columns = [];
            model.filterSettings.columns = [];
            model.groupSettings.columns = [];
            model.pageSettings.currentPage = 1;
            model.pageSettings.pageSize = 10;
            SetGridState(this.props.gridId, model, null);
            this.pageSettings = model.pageSettings;
            this.sortingOptions = model.sortSettings;
            this.filterSettings = model.filterSettings;
            this.groupSettings = model.groupSettings;
          }
        }
      }

      setTimeout((e) => {
        let sort: RAFSort = { field: "", order: "" };
        let objCustomFilter: RAFCustomFilter = {};
        let pageNumber = 1;
        let pageSize = 10;

        let model: GridModel = null;
        if (isNotNullAndUndefined(this.props.gridId)) {
          model = GetGridModelFromGridState(this.props.gridId);
        }
        if (isNotNullAndUndefined(model)) {
          if (
            isNotNullAndUndefined(model.sortSettings) &&
            isNotNullAndUndefined(model.sortSettings.columns) &&
            model.sortSettings.columns.length > 0 &&
            isNotNullAndUndefined(model.sortSettings.columns[0].field)
          ) {
            sort.field = model.sortSettings.columns[0].field;
            sort.order = model.sortSettings.columns[0].direction;
          } else {
            if (
              isNotNullAndUndefined(this.props.sortBy) &&
              isNotNullAndUndefined(this.props.sortBy.field)
            ) {
              sort.field = this.props.sortBy.field;
              sort.order = this.props.sortBy.order;
            } else {
              sort = null;
            }
          }
          if (
            isNotNullAndUndefined(model.filterSettings) &&
            isNotNullAndUndefined(model.filterSettings.columns)
          ) {
            objCustomFilter = getCustomFilterBySFGridFilter1(
              model.filterSettings.columns
            );
          }
          pageNumber = isNotNullAndUndefined(model.pageSettings)
            ? model.pageSettings.currentPage
            : 1;
          pageSize = isNotNullAndUndefined(model.pageSettings)
            ? model.pageSettings.pageSize
            : 10;
        }
        if (!this.isRemote) {
          this.grid.dataSource = this.state.data;
        } else {
          this.showGridSpinner();
          this.props.actions.fetchData(
            pageNumber,
            pageSize,
            sort,
            objCustomFilter
          );
        }
      }, 100);
    }
  };

  private changeGridContentHeight = () => {
    if (
      isNotNullAndUndefined(this.grid) &&
      isNotNullAndUndefined(this.grid.element)
    ) {
      let eFilterbar = this.grid.element.querySelector(".e-filterbar");
      let eGridpager = this.grid.element.querySelector(".e-gridpager");
      let eColumnheader = this.grid.element.querySelector(".e-columnheader");
      let filterMessageBar = document.getElementById("filterMessageBar");
      let resToolbar = this.grid.element.querySelector(".e-res-toolbar");

      let eGridcontent = this.grid.element.querySelector(
        ".e-gridcontent"
      ) as HTMLElement | null;
      let eGridpagerWidth = eGridpager && eGridpager.clientHeight;
      let eFilterbarWidth = eFilterbar && eFilterbar.clientHeight;
      let eColumnheaderWidth = eColumnheader && eColumnheader.clientHeight;
      let resToolbarHeight = resToolbar && resToolbar.clientHeight;
      let filterMessageBarWidth =
        filterMessageBar && filterMessageBar.offsetHeight;
      let contentHeight =
        eGridpagerWidth +
        eFilterbarWidth +
        eColumnheaderWidth +
        filterMessageBarWidth +
        resToolbarHeight;
      if (isNotNullAndUndefined(eGridcontent))
        eGridcontent.style.height = "calc(100% - " + contentHeight + "px)";
    }
  };

  private getColumnsDirective = (fields?: QueryAttributeJM[]) => {
    if (
      isNotNullAndUndefined(this.props.children) &&
      isNotNullAndUndefined(this.props.children["props"]) &&
      isNotNullAndUndefined(this.props.children["props"].children)
    ) {
      const children1 = React.Children.toArray(
        this.props.children["props"].children
      );
      //const children1 = React.Children.toArray(this.props.children);
      if (
        isNotNullAndUndefined(children1) &&
        children1.length > 0 &&
        isNotNullAndUndefined(children1[0]["props"]) &&
        isNotNullAndUndefined(children1[0]["props"]["field"])
      ) {
        let retval = children1.map((item, i) => {
          const child = children1[i];
          let rafGridColumnProps: RAFGridColumnProps<any> = child["props"];
          let matchedAttribute: QueryAttributeJM =
            fields &&
            fields.find(
              (x) => x.PropertyName === rafGridColumnProps.field.toString()
            );
          return (
            <ColumnDirective
              key={i}
              field={rafGridColumnProps.field.toString()}
              type={rafGridColumnProps.type}
              format={
                rafGridColumnProps.type === SFColumnType.date
                  ? formatSFDateOption
                  : null
              }
              headerText={
                rafGridColumnProps.headerText ||
                rafGridColumnProps.field.toString()
              }
              isPrimaryKey={rafGridColumnProps.isPrimaryKey}
              minWidth={
                isNotNullAndUndefined(rafGridColumnProps.minWidth)
                  ? rafGridColumnProps.minWidth
                  : "150"
              }
              maxWidth={
                isNotNullAndUndefined(rafGridColumnProps.maxWidth)
                  ? rafGridColumnProps.maxWidth
                  : this.customMaxColumnWidthTemplate(matchedAttribute)
              }
              //headerTemplate={this.customHeaderTemplate.bind(this,matchedAttribute)}
              headerTemplate={this.customHeaderTemplate}
              width={
                isNotNullAndUndefined(rafGridColumnProps.width)
                  ? rafGridColumnProps.width
                  : this.customColumnWidthTemplate(matchedAttribute)
              }
              //autoFit={true}
              visible={rafGridColumnProps.visible}
              allowEditing={
                isNotNullAndUndefined(rafGridColumnProps.isEditable)
                  ? rafGridColumnProps.isEditable
                  : false
              }
              {...(matchedAttribute
                ? { filterBarTemplate: this.filterTemplate(matchedAttribute) }
                : {})}
              {...(matchedAttribute
                ? {
                  filter: {
                    type: "Menu",
                    ui: this.filterMenuTemplate(matchedAttribute),
                  },
                }
                : {})}
              template={
                isNotNullAndUndefined(rafGridColumnProps.template)
                  ? rafGridColumnProps.template
                  : this.defaultColumnTemplate
              }
              /*template={
                                              i === 0
                                                  ? this.defaultColumnTemplate.bind(this, true, null)
                                                  : this.defaultColumnTemplate.bind(this, false, null)
                                          }*/
              clipMode={i === 0 ? "Clip" : "Ellipsis"}
            ></ColumnDirective>
          );
        });
        return retval;
      }
    }
    let retval =
      fields &&
      fields.map((item, i) => {
        return (
          <ColumnDirective
            key={i}
            field={item.PropertyName}
            type={getSFColumnTypeByRAFDatatype(item.DataType)}
            //headerText={GetClientTerm(clientTerminologies, item.PropertyName, item.DisplayName)}
            headerText={item.DisplayName}
            isPrimaryKey={
              isNotNullAndUndefined(this.props.idField) &&
                this.props.idField === item.PropertyName
                ? true
                : false
            }
            minWidth={"150"}
            maxWidth={this.customMaxColumnWidthTemplate(item)}
            //headerTemplate={this.customHeaderTemplate.bind(this, item)}
            headerTemplate={this.customHeaderTemplate}
            width={this.customColumnWidthTemplate(item)}
            // autoFit={true}
            visible={
              Browser.isDevice === true &&
                this.mobileColCount > 0 &&
                i > this.mobileColCount - 1
                ? false
                : // (isNullOrUndefined(this.mobileColCount) ||
                // this.mobileColCount < 1 ||
                //   this.isAdaptive) &&
                true
            }
            filterBarTemplate={this.filterTemplate(item)}
            filter={{ type: "Menu", ui: this.filterMenuTemplate(item) }}
            allowEditing={
              isNotNullAndUndefined(item.IsEditable) ? item.IsEditable : false
            }
            template={this.defaultColumnTemplate}
            clipMode={i === 0 ? "Clip" : "Ellipsis"}
          ></ColumnDirective>
        );
      });
    return retval;
  };

  private renderGrid = (fields?: QueryAttributeJM[]) => {
    let gridData = { result: null, count: null };
    //console.log('renderGrid allowselection', allowSelection, " allowEditing:", allowEditing)
    if (isNotNullAndUndefined(this.state.data)) {
      gridData = {
        result: this.state.data.result,
        count: this.state.data.count,
      };
    }

    /*
            let addIdColumn: boolean = true;
            if (isNotNullAndUndefined(fields)) {
                if (fields.findIndex(x => x.isPrimaryKey) >= 0) {
                    addIdColumn = false;
                }
            }
    
            const commandModel: CommandModel[] = getCommandModel();
            */
    const commandModel: CommandModel[] = this.getCommandModel();

    return (
      <div
        className={
          this.isRelatedSection
            ? "e-bigger is-related-section"
            : "e-bigger h-100"
        }
      >
        <GridComponent
          dataSource={gridData}
          ref={(g) => (this.grid = g)}
          allowPaging
          pageSettings={this.pageSettings}
          allowSorting
          sortSettings={this.sortingOptions}
          allowFiltering={this.allowFiltering}
          // allowFiltering={true}
          {...(this.allowFiltering === true
            ? {
              filterSettings:
                !Browser.isDevice && !this.props.isAdaptive
                  ? this.filterSettings
                  : this.filterOptions,
            }
            : {})}
          allowMultiSorting={false}
          allowGrouping
          groupSettings={this.groupSettings}
          allowSelection={this.allowSelection}
          selectionSettings={this.selectionsettingsModel}
          allowResizing
          //clipMode={"EllipsisWithTooltip"}
          height="100%"
          gridLines={this.props.isRelatedSection ? "Horizontal" : "Both"}
          className={
            (this.props.cssClass ? this.props.cssClass + " " : "") +
            (this.allowFiltering
              ? "rafSFGrid hover-table-div h-100 "
              : "rafSFGrid hover-table-div h-100 ") +
            (this.isAdaptive ? "e-device" : " ")
          }
          enableAdaptiveUI
          rowRenderingMode={
            Browser.isDevice === true || this.isAdaptive
              ? "Vertical"
              : "Horizontal"
          }
          allowPdfExport
          pdfHeaderQueryCellInfo={this.pdfHeaderQueryCellInfo}
          pdfQueryCellInfo={this.pdfQueryCellInfo}
          allowExcelExport
          commandClick={this.commandClicked.bind(this)}
          recordClick={
            this.allowEditing && this.showEditColumn
              ? null
              : this.recordClicked.bind(this)
          }
          created={this.created.bind(this)}
          dataBound={this.dataBound.bind(this)}
          dataStateChange={
            this.isRemote ? this.dataStateChange.bind(this) : null
          }
          rowSelected={this.rowSelected.bind(this)}
          rowDeselected={this.rowDeSelected.bind(this)}
          actionBegin={this.actionBegin.bind(this)}
          actionComplete={this.actionComplete.bind(this)}
          {...(this.props.rowTemplate
            ? { rowTemplate: this.props.rowTemplate }
            : {})}
          frozenColumns={
            this.props.frozenColumns ? this.props.frozenColumns : null
          }
        >
          <ColumnsDirective>
            {Browser.isDevice !== true && this.allowSelection === true && (
              <ColumnDirective
                type="checkbox"
                width="40px"
                maxWidth="40px"
                minWidth="40px"
                allowResizing={false}
                //autoFit
                textAlign="Center"
                customAttributes={{ class: "gridcheckboxclass" }}
                allowEditing={false}
                editTemplate={this.emptyTemplate}
              />
            )}
            {!Browser.isDevice &&
              isNotNullAndUndefined(commandModel) &&
              commandModel.length > 0 && (
                <ColumnDirective
                  commands={commandModel}
                  headerText="Action"
                  width="160px"
                  maxWidth={"160px"}
                  minWidth={"160px"}
                  textAlign="Center"
                  allowFiltering={false}
                  allowGrouping={false}
                  allowReordering={false}
                  allowResizing
                  allowSearching={false}
                  allowSorting={false}
                  filterTemplate={this.emptyTemplate}
                />
              )}
            {this.getColumnsDirective(fields)}
          </ColumnsDirective>

          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Group,
              Edit,
              Toolbar,
              Freeze,
              Resize,
              PdfExport,
              ExcelExport,
              CommandColumn,
            ]}
          />
        </GridComponent>
      </div>
    );
  };

  private getEmptyRecordMessage = () => {
    let emptyRecord = "";
    if (this.emptyStateProps) {
      emptyRecord =
        "<div class='empty-state-outer'><div class='empty-state-container no-record-div'>";
      let stateClassName = isNotNullAndUndefined(this.emptyStateProps.image)
        ? "empty-state-image"
        : "empty-state-icon";
      if (this.emptyStateProps.image || this.emptyStateProps.iconClass) {
        emptyRecord = emptyRecord + "<div class=" + stateClassName + ">";
        if (this.emptyStateProps.image) {
          emptyRecord =
            emptyRecord +
            "<img src='" +
            this.emptyStateProps.image +
            "' alt='Icon Image' class='' />";
        } else {
          emptyRecord =
            emptyRecord +
            "<span class='" +
            this.emptyStateProps.iconClass +
            "'></span>";
        }
        emptyRecord = emptyRecord + "</div>";
      }
      if (this.emptyStateProps.title) {
        emptyRecord = emptyRecord + "<div class='empty-state-title'>";
        emptyRecord =
          emptyRecord +
          "<span class='no-record-text1'>" +
          this.emptyStateProps.title +
          "</span>";
        emptyRecord = emptyRecord + "</div>";
      }
      if (this.emptyStateProps.body) {
        emptyRecord = emptyRecord + "<div class='empty-state-content'>";
        emptyRecord =
          emptyRecord + "<span>" + this.emptyStateProps.body + "</span>";
        emptyRecord = emptyRecord + "</div>";
      }

      emptyRecord = emptyRecord + "</div></div>";
    } else {
      emptyRecord =
        '<div class="no-record-div" id="noRecordDiv11" style="text-align: center;"><img src="' +
        noRecordIcon +
        '" width="52px" class="my-3" /><span class="no-record-text">No data available at the moment!</span><span class="no-record-text1"></span></div>';
    }

    return emptyRecord;
  };

  public Export(exportType: ExportType) {
    if (this.grid && this.grid) {
      if (!this.isRemote) {
        const exportData = this.state.data; //(DataUtil.parse.parseJson(gridData));
        if (exportType === "Pdf") {
          const exportProperties: PdfExportProperties = {
            dataSource: exportData,
            fileName: `${!IsNullOrWhiteSpace(this.props.moduleName)
              ? this.props.moduleName
              : "Data"
              }.pdf`,
          };
          this.grid.pdfExport(exportProperties);
        } else if (exportType === "Csv") {
          const excelExportProperties: ExcelExportProperties = {
            dataSource: exportData,
            fileName: `${!IsNullOrWhiteSpace(this.props.moduleName)
              ? this.props.moduleName
              : "Data"
              }.csv`,
          };
          this.grid.csvExport(excelExportProperties);
        } else if (exportType === "Excel") {
          const excelExportProperties: ExcelExportProperties = {
            dataSource: exportData,
            fileName: `${!IsNullOrWhiteSpace(this.props.moduleName)
              ? this.props.moduleName
              : "Data"
              }.xlsx`,
          };
          this.grid.excelExport(excelExportProperties);
        }
      } else {
        this.props.actions.getDataForExport().then((gridData) => {
          const exportData = gridData.result; //(DataUtil.parse.parseJson(gridData));
          if (exportType === "Pdf") {
            const exportProperties: PdfExportProperties = {
              dataSource: exportData,
              fileName: `${!IsNullOrWhiteSpace(this.props.moduleName)
                ? this.props.moduleName
                : "Data"
                }.pdf`,

              //theme: {
              //    header: {
              //        font: new PdfStandardFont(PdfFontFamily.TimesRoman, 11, PdfFontStyle.Bold),
              //        //caption: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 9) },
              //        //record: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 10) }
              //    }
              //},

              //theme: {
              //    header: {
              //        fontColor: '#333333',
              //        fontName: 'Segoe UI',
              //        fontSize: 12, bold: true,  //borders: { color: '#64FA50', lineStyle: 'Thin' }
              //    },
              //    record: {
              //        fontColor: '#333333',
              //        fontName: 'Segoe UI',
              //        //font: new PdfTrueTypeFont() { IsTrueType = true, FontFamily = /*you fonts famiy in form of base64string*/ },
              //        fontSize: 13
              //    },
              //    //caption: {
              //    //    fontColor: '#333333', fontName: 'Segoe UI', fontSize: 13
              //    //}
              //},
              //pageOrientation: 'Landscape',
            };
            this.grid.pdfExport(exportProperties);
          } else if (exportType === "Csv") {
            const excelExportProperties: ExcelExportProperties = {
              dataSource: exportData,
              fileName: `${!IsNullOrWhiteSpace(this.props.moduleName)
                ? this.props.moduleName
                : "Data"
                }.csv`,
            };
            this.grid.csvExport(excelExportProperties);
          } else if (exportType === "Excel") {
            const excelExportProperties: ExcelExportProperties = {
              dataSource: exportData,
              fileName: `${!IsNullOrWhiteSpace(this.props.moduleName)
                ? this.props.moduleName
                : "Data"
                }.xlsx`,
            };
            this.grid.excelExport(excelExportProperties);
          }
        });
      }
    }
  }

  private created = () => {
    let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
    if (this.grid && this.grid.element) {
      const gridParentDiv = this.grid.element.closest("#divGridParent");
      if (gridParentDiv) {
        const customSpinner = gridParentDiv.querySelector(
          "#customSpinner"
        ) as HTMLElement;
        if (
          isNotNullAndUndefined(customSpinner) &&
          !IsNullOrWhiteSpace(customSpinner.innerHTML)
        ) {
          spinnerTarget = gridParentDiv.querySelector(
            "#customSpinner"
          ) as HTMLElement;
          customSpinner.classList.remove("hidden");
        }
      }
    }

    createSpinner({
      target: spinnerTarget,
      width: "20px",
      template:
        '<div class="row g-0 wrapper-content loadingPanelGrid"> <div class="d-flex flex-column align-items-center" > <img src=' +
        loaderIcon +
        ' width="52" /> <span class="loadingText">Loading...</span> </div> </div >',
    });
    showSpinner(spinnerTarget);

    let emptyRecord: string = this.getEmptyRecordMessage();

    if (!IsNullOrWhiteSpace(emptyRecord) && isNotNullAndUndefined(this.grid)) {
      if (this.grid["localeStrings"]) {
        this.grid["localeStrings"]["EmptyRecord"] = emptyRecord;
      }
      if (this.grid.localeObj["localeStrings"]) {
        this.grid.localeObj["localeStrings"]["EmptyRecord"] = emptyRecord;
      }
      this.grid.localeObj["currentLocale"] = {
        EmptyRecord: emptyRecord,
      };
    }

    if (
      isNotNullAndUndefined(this.grid) &&
      isNotNullAndUndefined(this.props.created)
    ) {
      this.props.created(this.grid);
    }

    /*if (showHeader) {
    
            }
            else {
                grid.getHeaderTable().classList.add('hidden');
                grid.getHeaderTable().parentElement.parentElement.classList.add('borderTop0');
                grid.getHeaderTable().parentElement.parentElement.parentElement.classList.add('borderBottom0');
            }*/
  };

  private dataBound = (args) => {
    if (isNotNullAndUndefined(this.grid)) {
      const currentViewRecords = this.grid.getCurrentViewRecords();
      this.grid.element.classList.remove("hidden");

      if (
        isNotNullAndUndefined(currentViewRecords) &&
        currentViewRecords.length > 0
      ) {
        this.grid.element.classList.add("fade-in");
        document.querySelector(".e-spinner-pane").classList.add("hidden");
        // if (!this.allowFiltering) {
        //   this.grid.getHeaderContent().classList.remove("hidden");
        // }
      } else {
        document.querySelector(".e-spinner-pane").classList.add("hidden");
        // if (!this.allowFiltering) {
        //   this.grid.getHeaderContent().classList.add("hidden");
        // }
      }

      this.grid.getPager().classList.add("hidden");
      if (isNotNullAndUndefined(this.grid.dataSource)) {
        if (
          isNotNullAndUndefined(this.grid.dataSource["count"]) &&
          this.grid.dataSource["count"] > 10
        ) {
          this.grid.getPager().classList.remove("hidden");
        }

        if (
          isNotNullAndUndefined(this.grid.dataSource["count"]) &&
          this.grid.dataSource["count"] > this.grid.pageSettings.pageSize
        ) {
          this.grid.getPager().classList.remove("hidden");
        } else if (
          isNotNullAndUndefined(this.grid.dataSource["length"]) &&
          this.grid.dataSource["length"] > this.grid.pageSettings.pageSize
        ) {
          this.grid.getPager().classList.remove("hidden");
        }
      }

      if (isNotNullAndUndefined(this.grid.filterModule)) {
        Object.assign(this.grid.filterModule.filterOperators, {
          startsWith: "contains",
        });
      }
      const tgridWidth = this.grid.widthService.getTableWidth(
        this.grid.getColumns()
      );
      this.grid.widthService.setMinwidthBycalculation(Number(tgridWidth));
    }

    // no record table width
    // no record table width
    let rafGridElement = isNotNullAndUndefined(this.grid)
      ? this.grid.element.closest(".rafSFGrid")
      : document.querySelector(".rafSFGrid");
    let rafGridElementTableElement =
      rafGridElement && rafGridElement.querySelectorAll(".e-table");
    let rafGridContentContainer = isNotNullAndUndefined(this.grid)
      ? this.grid.element.closest(".grid-content-container")
      : document.querySelector(".grid-content-container");
    if (isNotNullAndUndefined(this.grid)) {
      let currentGridLength = this.grid.getCurrentViewRecords().length;
      // if (isNotNullAndUndefined(rafGridElementTableElement)) {
      //     rafGridElementTableElement.forEach((element) => {
      //         if (currentGridLength > 0) {
      //             element.classList.remove("no-record");
      //         } else {
      //             element.classList.add("no-record");
      //         }
      //     });
      // }
      if (isNotNullAndUndefined(rafGridContentContainer)) {
        if (currentGridLength > 0) {
          rafGridContentContainer.classList.remove("empty-grid");
        } else {
          rafGridContentContainer.classList.add("empty-grid");
        }
      }

      const emptyRow = rafGridElement.querySelector(".e-emptyrow>td");
      if (emptyRow) {
        // changing the message as default once data is loaded
        emptyRow.innerHTML = this.getEmptyRecordMessage();
      }
    }
    this.hideGridSpinner();
  };

  private dataStateChange = (state1: DataStateChangeEventArgs) => {
    //console.log('dataStateChange', state);
    if (this.grid) {
      let toolbarMenu = document.getElementById(
        this.grid.element.id + "_toolbarItems"
      );
      if (isNotNullAndUndefined(toolbarMenu))
        toolbarMenu.classList.add("hidden");

      const actionRequestType: string = state1.action.requestType;
      if (actionRequestType === "filtering") {
        let objCustomFilter: RAFCustomFilter = getCustomFilterBySFGridFilter(
          state1 && state1.where
        );
        this.hideToolbarMenu();
        this.showGridSpinner();
        this.props.actions.filtering(objCustomFilter);
        this.grid.clearSelection();
      } else if (actionRequestType === "paging") {
        this.hideToolbarMenu();
        this.showGridSpinner();
        this.props.actions.paging(state1.skip / state1.take + 1, state1.take);
        this.grid.clearSelection();
      } else if (actionRequestType === "sorting") {
        if (
          isNotNullAndUndefined(state1.sorted) &&
          state1.sorted.length > 0 &&
          isNotNullAndUndefined(state1.sorted[0].name)
        ) {
          this.hideToolbarMenu();
          this.showGridSpinner();
          this.props.actions.sorting({
            field: state1.sorted[0].name,
            order: state1.sorted[0].direction,
          });
          this.grid.clearSelection();
        } else {
          this.hideToolbarMenu();
          this.showGridSpinner();
          this.props.actions.sorting(null);
          this.grid.clearSelection();
        }
      } else {
        this.hideGridSpinner();
      }
    }
  };

  private pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs) => {
    (args.cell as any).row.pdfGrid.repeatHeader = true;
  };

  private pdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs) => {
    if (args.column.type === SFColumnType.date) {
      if (isNotNullAndUndefined(args.value)) {
        let localDate = convertUTCDateStringToLocalTimezone(
          args.value.toString()
        );
        //args.value = (moment(localDate).format('DD/MM/YYYY'));
        args.value = moment(localDate).format("DD/MM/YYYY hh:mm A");
      }
    }
    if (args.column.type === SFColumnType.datetime) {
      if (isNotNullAndUndefined(args.value)) {
        let localDate = convertUTCDateStringToLocalTimezone(
          args.value.toString()
        );
        args.value = moment(localDate).format("DD/MM/YYYY hh:mm A");
      }
    }
  };

  private commandClicked = (e: CommandClickEventArgs) => {
    const classList = e.target.classList;
    if (classList.contains("e-editbutton")) {
      if (this.allowEditing && this.showEditColumn) {
        //default grid inline editing
      } else {
        if (this.props.editClick) {
          this.props.editClick(e.rowData);
        }
      }
    } else if (classList.contains("e-custom-actionbutton")) {
      if (this.props.customActionClick) {
        this.props.customActionClick(e.rowData);
      }
    } else if (classList.contains("e-custombutton")) {
      if (this.props.customClick) {
        this.props.customClick(e.rowData);
      }
    }
  };

  private recordClicked = (e: RecordClickEventArgs) => {
    const classList = e.target.classList;
    if (
      classList.contains("e-gridchkbox") ||
      classList.contains("e-checkbox-wrapper") ||
      classList.contains("noRowclick") ||
      classList.contains("e-uncheck") ||
      classList.contains("e-check") ||
      (isNotNullAndUndefined(e.target.closest(".e-gridchkbox")) &&
        isNotNullAndUndefined(e.target.closest(".e-gridchkbox").innerHTML))
    )
      return;
    else if (
      isNotNullAndUndefined(e.target.closest(".e-unboundcell")) &&
      isNotNullAndUndefined(e.target.closest(".e-unboundcell").innerHTML)
    ) {
      return;
    } else if (
      isNotNullAndUndefined(e.target.closest(".noRowclick")) &&
      isNotNullAndUndefined(e.target.closest(".noRowclick").innerHTML)
    ) {
      return;
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-editbutton")) &&
      isNotNullAndUndefined(e.target.closest(".e-editbutton").innerHTML)
    ) {
      if (this.props.editClick) {
        this.props.editClick(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-custombutton")) &&
      isNotNullAndUndefined(e.target.closest(".e-custombutton").innerHTML)
    ) {
      if (this.props.customClick) {
        this.props.customClick(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-custom-actionbutton")) &&
      isNotNullAndUndefined(
        e.target.closest(".e-custom-actionbutton").innerHTML
      )
    ) {
      if (this.props.customActionClick) {
        this.props.customActionClick(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".rafGridActionContentLib")) &&
      isNotNullAndUndefined(
        e.target.closest(".rafGridActionContentLib").innerHTML
      )
    ) {
      return;
    } else {
      if (this.props.rowClick) {
        this.props.rowClick(e.rowData);
      }
    }
  };

  onActionMenuClicked = (selectedMenu: MenuEventArgs, data) => {
    selectedMenu.event.stopPropagation();
    if (this.props.actionButtonClicked) {
      this.props.actionButtonClicked(selectedMenu.item.id, data);
    }
  };

  private rowSelected = (e: any) => {
    if (isNotNullAndUndefined(this.grid)) {
      this.selectedRecords = this.grid.getSelectedRecords();
      if (this.props.rowSelected) {
        this.props.rowSelected(this.grid.getSelectedRecords());
      }
    }
  };

  private rowDeSelected = (e: any) => {
    if (isNotNullAndUndefined(this.grid)) {
      this.selectedRecords = this.grid.getSelectedRecords();
      if (this.props.rowSelected) {
        this.props.rowSelected(this.grid.getSelectedRecords());
      }
    }
  };

  private actionBegin = (args) => {
    const actionRequestType: Action = args.requestType;
    if (actionRequestType === "save") {
      const objData = args.data;
      args.cancel = true;
      this.grid.closeEdit(); //cancel the edit
      if (this.props.saveData) {
        this.props.saveData(objData, this.props.idField).then((gridData) => {
          this.grid.setRowData(objData[this.props.idField], objData); // update the edited data in Grid while click on the another cell
        });
      }
    }
    if (args.requestType === "filterbeforeopen") {
      args.filterModel.customFilterOperators.stringOperator =
        this.stringOperators;
      let allFields: QueryAttributeJM[] = this.props.fields;
      let selectedField: QueryAttributeJM =
        allFields && allFields.find((x) => x.PropertyName === args.columnName);
      if (selectedField && selectedField.DataType === RAFDataType.Date) {
        args.filterModel.customFilterOperators.dateOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
      if (selectedField && selectedField.DataType === RAFDataType.Dropdown) {
        args.filterModel.customFilterOperators.stringOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
      if (selectedField && selectedField.DataType === RAFDataType.Boolean) {
        args.filterModel.customFilterOperators.stringOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
      if (selectedField && selectedField.DataType === RAFDataType.Lookup) {
        args.filterModel.customFilterOperators.stringOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
    } else {
      const actionRequestType: Action = args.requestType;
      switch (actionRequestType) {
        case "filtering":
        case "grouping":
        case "paging":
        case "refresh":
        case "sorting":
        case "ungrouping":
          if (
            isNotNullAndUndefined(this.props.gridId) &&
            isNotNullAndUndefined(this.grid)
          )
            SetGridState(
              this.props.gridId,
              JSON.parse(this.grid.getPersistData()),
              null
            );
          break;
        default:
          break;
      }
    }
  };

  private actionComplete = (args: ActionEventArgs) => {
    if (this.grid) {
      const filterColumnsModel = this.grid.filterSettings.columns;
      const norecordsText1Element = this.grid.element.querySelector(
        ".no-record-div .no-record-text1"
      );
      if (isNotNullAndUndefined(norecordsText1Element)) {
        norecordsText1Element.textContent = "";
        if (
          isNotNullAndUndefined(filterColumnsModel) &&
          filterColumnsModel.length > 0
        ) {
          norecordsText1Element.textContent =
            "We're sorry. We cannot find any matches for your search criteria.";
        } else if (this.hasOtherViews && this.hasOtherViews === true) {
          norecordsText1Element.textContent =
            "This view may contain filters. Please try with a different view.";
        }
      }
      const filterMessage: string =
        this.ConstructFilterMessage(filterColumnsModel);
      //const gridContentDiv = document.querySelector('.rafSFGrid');
      const gridContentDiv = document.getElementById(
        this.grid && this.grid.element.id
      );
      const filterMessageBar =
        gridContentDiv &&
        gridContentDiv.parentElement.querySelector(".filterMessageBar");

      let filterBarDiv =
        '<div id="filterMessageBar" class="filterMessageBar"><div class="filterMessageBarInner d-flex fade-in"> <div> ' +
        filterMessage +
        '</div> <div class="d-flex"><span style="cursor:pointer" title="Clear search criteria" class="fas fa-xmark clearFilter"></span> </div> </div> </div>';
      if (isNotNullAndUndefined(gridContentDiv) !== null) {
        if (
          isNotNullAndUndefined(filterMessage) &&
          !IsNullOrWhiteSpace(filterMessage)
        ) {
          if (isNotNullAndUndefined(filterMessageBar))
            filterMessageBar.remove();
          gridContentDiv.insertAdjacentHTML("afterbegin", filterBarDiv);
          const clearFilter = document.querySelector(".clearFilter");
          if (isNotNullAndUndefined(clearFilter))
            clearFilter.addEventListener("click", () => {
              this.grid.clearSelection();
              this.grid.clearFiltering();
              this.refreshData(true);
              setTimeout((e) => {
                if (this.grid) {
                  this.grid.refreshHeader();
                }
              }, 10);
            });
          setTimeout((e) => {
            this.changeGridContentHeight();
          });
        } else {
          if (isNotNullAndUndefined(filterMessageBar))
            filterMessageBar.remove();
          setTimeout((e) => {
            this.changeGridContentHeight();
          });
        }
      }

      const actionRequestType: Action = args.requestType;
      switch (actionRequestType) {
        case "filtering":
        case "grouping":
        case "paging":
        case "refresh":
        case "sorting":
        case "ungrouping":
          if (
            isNotNullAndUndefined(this.props.gridId) &&
            isNotNullAndUndefined(this.grid)
          )
            SetGridState(
              this.props.gridId,
              JSON.parse(this.grid.getPersistData()),
              null
            );
          break;
        case "cancel":
          setTimeout((e) => {
            this.grid.clearRowSelection();
          }, 10);
          break;
        default:
          break;
      }
    }
  };

  private toolbarActionClickHandler = (args: ClickEventArgs) => {
    if (args.item.text === "Delete") {
      if (this.props.deleteClicked) {
        this.props.deleteClicked(this.grid.getSelectedRecords());
        this.grid.clearSelection();
      }
    }
  };

  private beforeRender = (args) => {
    let innerText = args.target.closest("td").innerText;
    let closestTd = args.target.closest("td");
    this.tooltip.content = innerText;
    this.tooltip.cssClass = "";
    if (IsNullOrWhiteSpace(innerText)) {
      this.tooltip.cssClass = "d-none";
    }
    if (
      isNotNullAndUndefined(closestTd) &&
      isNotNullAndUndefined(closestTd.querySelector(".removeTooltip"))
    ) {
      this.tooltip.cssClass = "d-none";
    }
  };

  private emptyTemplate = (props1: any) => {
    return <div></div>;
  };

  /***/
  private filterTemplate = (props1: QueryAttributeJM) => {
    const objSuffix = `${Constants.idSuffix}${IsNullOrWhiteSpace(this.props.viewId)
      ? IsNullOrWhiteSpace(this.props.viewName)
        ? IsNullOrWhiteSpace(this.props.gridId)
          ? ""
          : this.props.gridId
        : this.props.viewName
      : this.props.viewId
      }`;
    let filterVal = null;
    let model: GridModel = null;
    if (isNotNullAndUndefined(this.props.gridId)) {
      model = GetGridModelFromGridState(this.props.gridId);
      if (isNotNullAndUndefined(model)) {
        const objfilterSettings: FilterSettingsModel = model.filterSettings;
        if (
          isNotNullAndUndefined(objfilterSettings) &&
          isNotNullAndUndefined(objfilterSettings.columns)
        ) {
          const objFilterCol = objfilterSettings.columns.find(
            (x) => x.field === props1.PropertyName
          );
          if (isNotNullAndUndefined(objFilterCol)) {
            filterVal = objFilterCol.value;
          }
        }
      }
    }
    let retVal: IFilterUI;
    if (props1) {
      if (props1.DataType === RAFDataType.Dropdown) {
        if (
          isNotNullAndUndefined(props1.UIType) &&
          props1.UIType === RAFUIType.ComboBox
        ) {
          retVal = {
            create: () => {
              let valueElement = document.createElement("input");
              valueElement.setAttribute("type", "text");
              valueElement.id = props1.PropertyName + objSuffix;
              return valueElement;
            },
            write: (args: {
              element: Element;
              values: string[] | string;
              column: Column;
            }) => {
              let dataSource: { [key: string]: Object; }[] = [];
              props1.ValueJson &&
                props1.ValueJson.forEach((opt) => {
                  dataSource.push({
                    label: opt.DisplayName,
                    value: opt.DisplayName,
                  });
                });

              let fields: object = { text: "label", value: "value" };
              let comboBox = new ComboBox({
                dataSource: dataSource,
                fields: fields,
                value: args.values
                  ? (args.values as string)
                  : isNotNullAndUndefined(filterVal)
                    ? filterVal
                    : null,
                change: (e: any) => {
                  if (e.itemData)
                    this.grid.filterByColumn(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      "contains",
                      e.itemData !== null ? e.itemData.value : null
                    );
                  else
                    this.grid.removeFilteredColsByField(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      true
                    );
                },
                placeholder: props1.DisplayName,
                showClearButton: true,
              });
              comboBox.appendTo("#" + args.element.id);
            },
          };
        } else {
          retVal = {
            create: () => {
              let valueElement = document.createElement("input");
              valueElement.setAttribute("type", "text");
              valueElement.id = props1.PropertyName + objSuffix;
              return valueElement;
            },
            write: (args: {
              element: Element;
              values: string[] | string;
              column: Column;
            }) => {
              let dataSource: { [key: string]: Object; }[] = [];
              if (props1.IsRequired === false) {
                dataSource.push({
                  label: "None",
                  value: "None",
                });
              }
              props1.ValueJson &&
                props1.ValueJson.forEach((opt: ValueJson) => {
                  dataSource.push({
                    label: opt.DisplayName,
                    value:
                      isNotNullAndUndefined(props1.UIType) &&
                        (props1.UIType === RAFUIType.RadioButton ||
                          props1.UIType === RAFUIType.ToggleButton)
                        ? opt.Name
                        : opt.DisplayName,
                  });
                });
              let fields: object = { text: "label", value: "value" };
              let dropDownObj = new DropDownList({
                dataSource: dataSource,
                fields: fields,
                value: args.values
                  ? (args.values as string)
                  : isNotNullAndUndefined(filterVal)
                    ? filterVal
                    : null,
                change: (e: any) => {
                  if (e.itemData)
                    this.grid.filterByColumn(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      props1.IsMultiSelect ? "contains" : "equal",
                      e.itemData !== null ? e.itemData.value : null
                    );
                  else
                    this.grid.removeFilteredColsByField(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      true
                    );
                },
                placeholder: props1.DisplayName,
                showClearButton: true,
              });
              dropDownObj.appendTo("#" + args.element.id);
            },
          };
        }
      } else if (props1.DataType === RAFDataType.Lookup) {
        let url;
        if (isNotNullAndUndefined(props1.RelatedEntities)) {
          const firstItem = props1.RelatedEntities;
          //url = `${getURLPrefixByModuleName(props1.RelatedEntities) / LookUpDropDown}`;
          url = `${getURLPrefixByModuleName(
            props1.RelatedEntities
          )}/LookUpDropDown`;

          // url = isNotNullAndUndefined(firstItem)
          //     ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
          //     : null;
        }
        let retVal1 = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args: {
            element: Element;
            values: string[] | string;
            column: Column;
          }) => {
            const lookupFields = { text: "Value", value: "Value" };
            let skip = 0;
            let take: number = Constants.DropdownFetchCount;
            let filteredSkip = 0;
            let filteredTake: number = Constants.DropdownFetchCount;
            const lookupData: RAFDataManager = new RAFDataManager({
              adaptor: new RAFUrlAdaptor({
                Skip: 0,
                Take: Constants.DropdownFetchCount,
                entityName: props1.RelatedEntities,
              }),
              crossDomain: true,
              url: Constants.baseAPIUrl + url,
              requestType: "POST",
              headers: [],
            });
            const setFilteringDebounce = debounce((args1) => {
              filteredSkip = 0;
              filteredTake = Constants.DropdownFetchCount;
              lookupData.adaptor = new RAFUrlAdaptor({
                Skip: filteredSkip,
                Take: filteredTake,
                entityName: props1.RelatedEntities,
              });
              let query = new Query();
              query =
                args1.text !== ""
                  ? query.where(
                    props1.PropertyName,
                    "contains",
                    args1.text,
                    true
                  )
                  : query;
              args1.updateData(lookupData, query);
            }, 500);
            const actionBegin = (args1) => {
              if (args1.query === undefined) {
                //temp fix to prevent repeated api calls
                args1.cancel = true;
              }
            };
            const actionComplete = (e) => {
              e.result = R.uniq(e.result);
            };
            const opened = (args1) => {
              let listElement: HTMLElement = (lookupObj as any).list;
              listElement.addEventListener("scroll", (args) => {
                if (
                  listElement.scrollTop + listElement.offsetHeight + 1 >=
                  listElement.scrollHeight
                ) {
                  setScrollDebounce(args);
                }
              });
            };
            const onCreated = (args1) => {
              if (lookupObj) {
                const tooltip = new Tooltip({
                  beforeRender: (args1: TooltipEventArgs) => {
                    if (isNotNullAndUndefined(tooltip)) {
                      tooltip.content = args1.target.textContent;
                    }
                  },
                  content: "Loading...",
                  position: "TopCenter",
                  target:
                    isNotNullAndUndefined(lookupObj) &&
                      isNotNullAndUndefined(lookupObj.element)
                      ? `#${lookupObj.element.id}_popup .e-list-item`
                      : ".e-list-item",
                });
                tooltip.appendTo("body");
              }
            };
            const onClosed = (args1) => {
              let openTooltips = document.querySelectorAll(
                "div.e-tooltip-wrap.e-popup-open"
              );
              if (isNotNullAndUndefined(openTooltips)) {
                openTooltips.forEach((x) => {
                  x.classList.add("hidden");
                });
              }
            };
            const setScrollDebounce = debounce((args1) => {
              let filterQuery = isNotNullAndUndefined(lookupObj.query)
                ? lookupObj.query.clone()
                : new Query();
              const filteredText =
                lookupObj["searchKeyModule"]["element"]["value"];
              if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
                filteredSkip += filteredTake;
                filterQuery = filterQuery.where(
                  props1.PropertyName,
                  "contains",
                  filteredText,
                  true
                );
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: filteredSkip,
                  Take: filteredTake,
                });
              } else {
                skip += take;
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: skip,
                  Take: take,
                  entityName: props1.RelatedEntities,
                });
              }
              lookupData
                .executeQuery(filterQuery)
                .then((event) => {
                  lookupObj.addItem(
                    (event as any).result as { [key: string]: Object; }[]
                  );
                })
                .catch((e) => { });
            }, 500);
            const lookupObj: DropDownList = new DropDownList({
              value: isNotNullAndUndefined(args.values)
                ? (args.values as string)
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              placeholder: props1.DisplayName,
              showClearButton: true,
              change: (e: any) => {
                if (e.itemData)
                  this.grid &&
                    this.grid.filterByColumn(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      "equal",
                      e.itemData !== null ? e.itemData["Value"] : null
                    );
                else
                  this.grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
              },
              fields: lookupFields,
              dataSource: lookupData,
              allowFiltering: true,
              filterType: "Contains",

              filtering: (e: FilteringEventArgs) => {
                e.preventDefaultAction = true;
                setFilteringDebounce(e);
              },
              actionBegin: actionBegin,
              actionComplete: actionComplete,
              created: onCreated,
              close: onClosed,
              open: (e) => {
                opened(e);
              },
            });
            const objElement = document.querySelector("#" + args.element.id);
            if (
              isNotNullAndUndefined(objElement) &&
              isNotNullAndUndefined(objElement.classList) &&
              !objElement.classList.contains("e-dropdownlist")
            ) {
              lookupObj.appendTo("#" + args.element.id);
            }
          },
        };
      } else if (props1.DataType === RAFDataType.Date) {
        retVal = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args: { element: Element; values: Date; column: Column; }) => {
            const datePickerObj: DateRangePicker = new DateRangePicker({
              value: args.values
                ? (args.values as Date)
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              presets: this.presetDateModel,
              format: RAFDatePickerViewFormat.DATETIME,
              change: (e: any) => {
                if (e.value) {
                  this.grid.filterSettings.columns =
                    this.grid.filterSettings.columns.filter(
                      (x) =>
                        x.field !==
                        truncateAfter(args.element.id, Constants.idSuffix)
                    );
                  this.grid.filterSettings.columns.push({
                    value: e.value !== null ? e.value[0] : null,
                    operator: "greaterthanorequal",
                    field: truncateAfter(args.element.id, Constants.idSuffix),
                    predicate: "and",
                  });
                  this.grid.filterSettings.columns.push({
                    value: e.value !== null ? e.value[1] : null,
                    operator: "lessthanorequal",
                    field: truncateAfter(args.element.id, Constants.idSuffix),
                    predicate: "and",
                  });
                } else {
                  this.grid.filterSettings.columns =
                    this.grid.filterSettings.columns.filter(
                      (x) =>
                        x.field !==
                        truncateAfter(args.element.id, Constants.idSuffix)
                    );
                  this.grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
                }
              },
              placeholder: props1.DisplayName,
              showClearButton: true,
            });
            const objElement = document.querySelector("#" + args.element.id);
            if (
              isNotNullAndUndefined(objElement) &&
              isNotNullAndUndefined(objElement.classList) &&
              !objElement.classList.contains("e-daterangepicker")
            ) {
              datePickerObj.appendTo("#" + args.element.id);
            }
          },
        };
      } else if (props1.DataType === RAFDataType.Boolean) {
        retVal = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args: {
            element: Element;
            values: string[] | string;
            column: Column;
          }) => {
            let dataSource: { [key: string]: Object; }[] = [
              { label: "Yes", value: true },
              { label: "No", value: false },
            ];
            let fields: object = { text: "label", value: "value" };
            let dropDownObj = new DropDownList({
              dataSource: dataSource,
              fields: fields,
              value: args.values
                ? (args.values as string)
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              change: (e: any) => {
                if (e.itemData)
                  this.grid.filterByColumn(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    "equal",
                    e.itemData !== null ? e.itemData.value : null
                  );
                else
                  this.grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
              },
              placeholder: props1.DisplayName,
              showClearButton: true,
            });
            dropDownObj.appendTo("#" + args.element.id);
          },
        };
      } else {
        retVal = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args: {
            element: Element;
            values: string[] | string;
            column: Column;
          }) => {
            let textBox = new TextBox({
              type: "text",
              value: args.values
                ? (args.values as string)
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              change: (e: any) => {
                if (e.value) {
                } else {
                  this.grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
                }
              },
              placeholder: props1.DisplayName,
              showClearButton: true,
            });
            textBox.appendTo("#" + args.element.id);
          },
        };
      }
    }
    return retVal;
  };

  private filterMenuTemplate = (props: QueryAttributeJM): any => {
    let filterVal = null;
    let model: GridModel = null;
    if (isNotNullAndUndefined(this.props.gridId)) {
      model = GetGridModelFromGridState(this.props.gridId);
      if (isNotNullAndUndefined(model)) {
        const objfilterSettings: FilterSettingsModel = model.filterSettings;
        if (
          isNotNullAndUndefined(objfilterSettings) &&
          isNotNullAndUndefined(objfilterSettings.columns)
        ) {
          const objFilterCol = objfilterSettings.columns.filter(
            (x) => x.field === props.PropertyName
          );
          if (isNotNullAndUndefined(objFilterCol)) {
            if (objFilterCol.length > 1) {
              filterVal = [];
              objFilterCol.forEach((itemVal) => {
                filterVal.push(itemVal.value);
              });
            } else {
              filterVal = objFilterCol[0] && objFilterCol[0].value;
            }
          }
        }
      }
    }
    let retVal: IFilterMUI;
    if (props && props.DataType === RAFDataType.Text) {
      retVal = {
        create: (args: { target: Element; column: object; }) => {
          const flValInput: HTMLElement = createElement("input", {
            id: props.PropertyName,
            className: "flm-input",
          });
          args.target.appendChild(flValInput);
          this.textboxObj = new TextBox({
            placeholder: props.DisplayName,
            type: "text",
            showClearButton: true,
          });
          this.textboxObj.appendTo(flValInput);
        },
        read: (args: {
          target: Element;
          column: any;
          operator: string;
          fltrObj: Filter;
        }) => {
          args.fltrObj.filterByColumn(
            args.column.field,
            args.operator,
            this.textboxObj.value
          );
        },
        write: (args: {
          column: object;
          target: Element;
          parent: any;
          filteredValue: number | string;
        }) => {
          this.textboxObj.value = args.filteredValue
            ? args.filteredValue.toString()
            : null;
        },
      };
    }
    if (props && props.DataType === RAFDataType.Dropdown) {
      let dataSource: { [key: string]: Object; }[] = [];
      let fields: object = { text: "label", value: "value" };
      props.ValueJson &&
        props.ValueJson.forEach((opt: ValueJson) => {
          dataSource.push({ label: opt.DisplayName, value: opt.DisplayName });
        });
      retVal = {
        create: (args: { target: Element; column: object; }) => {
          const flValInput: HTMLElement = createElement("input", {
            id: "prop_" + props.PropertyName,
            className: "flm-input",
          });
          args.target.appendChild(flValInput);
          this.dropDownObj = new DropDownList({
            dataSource: dataSource,
            fields: fields,
            placeholder: "Select " + props.DisplayName,
          });
          this.dropDownObj.appendTo(flValInput);
        },
        read: (args: {
          target: Element;
          column: any;
          operator: string;
          fltrObj: Filter;
        }) => {
          args.fltrObj.filterByColumn(
            args.column.field,
            args.operator,
            this.dropDownObj.value as any
          );
        },
        write: (args: {
          column: object;
          target: Element;
          parent: any;
          filteredValue: number | string;
        }) => {
          this.dropDownObj.value = args.filteredValue;
        },
      };
    }
    if (props && props.DataType === RAFDataType.Boolean) {
      let dataSource: { [key: string]: Object; }[] = [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ];
      let fields: object = { text: "label", value: "value" };
      retVal = {
        create: (args: { target: Element; column: object; }) => {
          const flValInput: HTMLElement = createElement("input", {
            id: props.PropertyName,
            className: "flm-input",
          });
          args.target.appendChild(flValInput);
          this.booleandropDownObj = new DropDownList({
            dataSource: dataSource,
            fields: fields,
            placeholder: "Select " + props.DisplayName,
          });
          this.booleandropDownObj.appendTo(flValInput);
        },
        read: (args: {
          target: Element;
          column: any;
          operator: string;
          fltrObj: Filter;
        }) => {
          args.fltrObj.filterByColumn(
            args.column.field,
            args.operator,
            this.booleandropDownObj.value as any
          );
        },
        write: (args: {
          column: object;
          target: Element;
          parent: any;
          filteredValue: number | string | boolean;
        }) => {
          this.booleandropDownObj.value = args.filteredValue;
        },
      };
    }

    if (props && props.DataType === RAFDataType.Lookup) {
      let url;
      if (isNotNullAndUndefined(props.RelatedEntities)) {
        const firstItem = props.RelatedEntities;
        // url = isNotNullAndUndefined(firstItem)
        //     ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
        //     : null;
        url = `${getURLPrefixByModuleName(
          props.RelatedEntities
        )}/LookUpDropDown`;
      }

      retVal = {
        create: (args: { target: Element; column: object; }) => {
          const flValInput: HTMLElement = createElement("input", {
            id: props.PropertyName,
            className: "flm-input",
          });
          args.target.appendChild(flValInput);
          const lookupFields = { text: "Value", value: "Value" };
          //let isFiltering: boolean = false;
          let skip = 0;
          let take: number = Constants.DropdownFetchCount;
          let filteredSkip = 0;
          let filteredTake: number = Constants.DropdownFetchCount;
          const lookupData: RAFDataManager = new RAFDataManager({
            adaptor: new RAFUrlAdaptor({
              Skip: 0,
              Take: Constants.DropdownFetchCount,
              entityName: props.RelatedEntities,
            }),
            crossDomain: true,
            url: Constants.baseAPIUrl + url,
            requestType: "POST",
            //enableCaching: true,
            headers: [],
          });
          const setFilteringDebounce = debounce((args1) => {
            //isFiltering = true;
            filteredSkip = 0;
            filteredTake = Constants.DropdownFetchCount;
            lookupData.adaptor = new RAFUrlAdaptor({
              Skip: filteredSkip,
              Take: filteredTake,
              entityName: props.RelatedEntities,
            });
            let query = new Query();
            query =
              args1.text !== ""
                ? query.where(props.PropertyName, "contains", args1.text, true)
                : query;
            args1.updateData(lookupData, query);
          }, 500);
          const actionBegin = (args1) => {
            if (args1.query === undefined) {
              //temp fix to prevent repeated api calls
              args1.cancel = true;
            }
          };
          const actionComplete = (e) => {
            e.result = R.uniq(e.result);
          };
          const opened = (args1) => {
            let listElement: HTMLElement = (this.lookupObject as any).list;
            listElement.addEventListener("scroll", (args) => {
              if (
                listElement.scrollTop + listElement.offsetHeight + 1 >=
                listElement.scrollHeight
              ) {
                setScrollDebounce(args);
              }
            });
          };
          const onCreated = (args1) => {
            if (this.lookupObject) {
              const tooltip = new Tooltip({
                beforeRender: (args1: TooltipEventArgs) => {
                  if (isNotNullAndUndefined(tooltip)) {
                    tooltip.content = args1.target.textContent;
                  }
                },
                content: "Loading...",
                position: "TopCenter",
                target:
                  isNotNullAndUndefined(this.lookupObject) &&
                    isNotNullAndUndefined(this.lookupObject.element)
                    ? `#${this.lookupObject.element.id}_popup .e-list-item`
                    : ".e-list-item",
              });
              tooltip.appendTo("body");
            }
          };
          const onClosed = (args1) => {
            let openTooltips = document.querySelectorAll(
              "div.e-tooltip-wrap.e-popup-open"
            );
            if (isNotNullAndUndefined(openTooltips)) {
              openTooltips.forEach((x) => {
                x.classList.add("hidden");
              });
            }
          };
          const setScrollDebounce = debounce((args1) => {
            let filterQuery = isNotNullAndUndefined(this.lookupObject.query)
              ? this.lookupObject.query.clone()
              : new Query();
            const filteredText =
              this.lookupObject["searchKeyModule"]["element"]["value"];
            if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
              filteredSkip += filteredTake;
              filterQuery = filterQuery.where(
                props.PropertyName,
                "contains",
                filteredText,
                true
              );
              lookupData.adaptor = new RAFUrlAdaptor({
                Skip: filteredSkip,
                Take: filteredTake,
                entityName: props.RelatedEntities,
              });
            } else {
              skip += take;
              lookupData.adaptor = new RAFUrlAdaptor({
                Skip: skip,
                Take: take,
                entityName: props.RelatedEntities,
              });
            }
            lookupData
              //.executeQuery(filterQuery.range(start, end))
              .executeQuery(filterQuery)
              .then((event) => {
                //start = end;
                //end += 5;
                this.lookupObject.addItem(
                  (event as any).result as { [key: string]: Object; }[]
                );
              })
              .catch((e) => { });
          }, 500);
          this.lookupObject = new DropDownList({
            dataSource: lookupData,
            fields: lookupFields,
            allowFiltering: true,
            filterType: "Contains",
            placeholder: "Select " + props.DisplayName,
            filtering: (e: FilteringEventArgs) => {
              e.preventDefaultAction = true;
              setFilteringDebounce(e);
              //if (isNotNullAndUndefined(url)) {
              //    let query = new Query();
              //    // frame the query based on search string with filter type.
              //    query = (e.text !== "") ? query.where(props.PropertyName, "contains", e.text, true) : query;
              //    // pass the filter data source, filter query to updateData method.
              //    //args.updateData(this.searchData, query);
              //    e.updateData(lookupData, query);
              //}
            },
            actionBegin: actionBegin,
            actionComplete: actionComplete,
            created: onCreated,
            close: onClosed,
            open: (e) => {
              opened(e);
            },
          });
          this.lookupObject.appendTo(flValInput);
        },
        read: (args: {
          target: Element;
          column: any;
          operator: string;
          fltrObj: Filter;
        }) => {
          args.fltrObj.filterByColumn(
            args.column.field,
            args.operator,
            this.lookupObject.value as any
          );
        },
        write: (args: {
          column: object;
          target: Element;
          parent: any;
          filteredValue: number | string;
        }) => {
          this.lookupObject.value = args.filteredValue;
        },
      };
    }
    if (props && props.DataType === RAFDataType.Date) {
      retVal = {
        create: (args: { target: Element; column: object; }) => {
          let flValInput = createElement("input", {
            id: props.PropertyName,
            className: "flm-input",
          });
          args.target.appendChild(flValInput);

          this.datepickerObj = new DatePicker({
            placeholder: "Select " + props.DisplayName,
            format: RAFDatePickerViewFormat.DATE,
          });

          this.datepickerObj.appendTo(flValInput);
        },
        read: (args: {
          target: Element;
          column: any;
          operator: string;
          fltrObj: Filter;
        }) => {
          args.fltrObj.filterByColumn(
            args.column.field,
            args.operator,
            this.datepickerObj.value as Date
          );
        },
        write: (args: {
          column: object;
          target: Element;
          parent: any;
          filteredValue: Date;
        }) => {
          this.datepickerObj.value = args.filteredValue as Date;
        },
      };
    }
    if (props && props.DataType === 'RAFDataType.Date') {
      retVal = {
        create: (args: { target: Element; column: object; }) => {
          let flValInput = createElement("input", {
            id: props.PropertyName,
            className: "flm-input",
          });
          args.target.appendChild(flValInput);
          let fdate = [];
          this.grid.filterSettings.columns.forEach((col) => {
            if (col.field === props.PropertyName) fdate.push(col.value);
          });
          this.daterangepickerObj = new DateRangePicker({
            presets: this.presetDateModel,
            format: RAFDatePickerViewFormat.DATETIME,
          });
          if (fdate.length > 0) {
            this.daterangepickerObj.startDate = fdate[0];
            this.daterangepickerObj.endDate = fdate[1];
          }
          this.daterangepickerObj.appendTo(flValInput);
        },
        read: (args: {
          target: Element;
          column: any;
          operator: string;
          fltrObj: Filter;
        }) => {
          args.fltrObj.filterByColumn(
            args.column.field,
            args.operator,
            this.daterangepickerObj.value as Date[]
          );
        },
        write: (args: {
          column: object;
          target: Element;
          parent: any;
          filteredValue: number | string | Date | Date[];
        }) => {
          this.daterangepickerObj.value = args.filteredValue ? args.filteredValue as Date[] : (isNotNullAndUndefined(filterVal) ? filterVal : null);
        },
      };
    }
    return retVal;
  };

  private defaultColumnTemplate = (data) => {
    let item: QueryAttributeJM =
      this.props.fields &&
      this.props.fields.find((x) => x.PropertyName === data.column.field);
    let isFirstColumn = false;
    if (this.allowSelection) {
      if (Browser.isDevice !== true) {
        // This condition added if checkbox column is hidden for device
        isFirstColumn = data.column.index === 1 ? true : false;
      } else {
        isFirstColumn = data.column.index === 0 ? true : false;
      }
    } else {
      isFirstColumn = data.column.index === 0 ? true : false;
    }
    const fieldValue = data[data.column.field];
    //const gridTemplates: GridColumnTemplates[] = props.gridTemplates;

    if (isNotNullAndUndefined(this.gridTemplates)) {
      let templateName: GridColumnTemplates = this.gridTemplates.find(
        (x) => x.key.toLowerCase() === data.column.field.toLowerCase()
      );
      if (
        isNotNullAndUndefined(templateName) &&
        isNotNullAndUndefined(templateName.value)
      ) {
        if (
          isNotNullAndUndefined(getGridColumnTemplateItems[templateName.value])
        ) {
          //console.log('templateHelpers', templateHelpers);
          //console.log('templateName.value', templateName.value);
          return getGridColumnTemplateItems[templateName.value].element.call(
            this,
            this.props.moduleName,
            this.allowEditing,
            isFirstColumn,
            item,
            this.isDynamic,
            data
          );
        }
      }
    }

    if (isFirstColumn === true && !this.disableFirstColumnTemplate) {
      return getGridColumnTemplateItems.firstColumnTemplate.element.call(
        this,
        this.props.moduleName,
        this.allowEditing,
        isFirstColumn,
        item,
        this.isDynamic,
        data
      );
    }

    if (
      isNotNullAndUndefined(item) &&
      item.DataType === RAFDataType.Text &&
      item.PropertyName === "TagsListJson"
    ) {
      return getGridColumnTemplateItems.tagsTemplate.element.call(
        this,
        this.props.moduleName,
        this.allowEditing,
        isFirstColumn,
        item,
        this.isDynamic,
        data
      );
    }

    if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Date) {
      return getGridColumnTemplateItems.dateTemplate.element.call(
        this,
        this.props.moduleName,
        this.allowEditing,
        isFirstColumn,
        item,
        this.isDynamic,
        data
      );
    }

    if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.DateTime) {
      return getGridColumnTemplateItems.dateTimeTemplate.element.call(
        this,
        this.props.moduleName,
        this.allowEditing,
        isFirstColumn,
        item,
        this.isDynamic,
        data
      );
    }

    if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Dropdown) {
      //return templateHelpers['dropdownTemplate'].call(this, moduleName, allowEditing, isFirstColumn, item, data)
      return getGridColumnTemplateItems.defaultDropdownTemplate.element.call(
        this,
        this.props.moduleName,
        this.allowEditing,
        isFirstColumn,
        item,
        this.isDynamic,
        data
      );
    }

    if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Boolean) {
      return getGridColumnTemplateItems.isBooleanTemplate.element.call(
        this,
        this.props.moduleName,
        this.allowEditing,
        isFirstColumn,
        item,
        this.isDynamic,
        data
      );
    }
    if (
      isNotNullAndUndefined(item) &&
      item.DataType === RAFDataType.Lookup &&
      item.IsMultiSelect === true
    ) {
      return getGridColumnTemplateItems.lookupMultiSelectTemplate.element.call(
        this,
        this.props.moduleName,
        this.allowEditing,
        isFirstColumn,
        item,
        this.isDynamic,
        data
      );
    }

    if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Form) {
      return (
        <Aux>
          {!IsNullOrWhiteSpace(fieldValue) ? JSON.stringify(fieldValue) : ""}
        </Aux>
      );
    } else {
      return <Aux>{!IsNullOrWhiteSpace(fieldValue) ? fieldValue : ""}</Aux>;
    }
  };

  //private customHeaderTemplate = (item: QueryAttributeJM, data): any => {
  private customHeaderTemplate = (data): any => {
    let item: QueryAttributeJM =
      this.props.fields &&
      this.props.fields.find((x) => x.PropertyName === data.field);

    let headerText = "";
    const fieldValue = data["headerText"];
    const gridColumnHeaders: GridColumnHeader[] = this.props.gridColumnHeader;
    let headerVal: GridColumnHeader;
    if (
      isNotNullAndUndefined(gridColumnHeaders) &&
      isNotNullAndUndefined(item)
    ) {
      headerVal = gridColumnHeaders.find((x) => x.field === item.PropertyName);
      if (
        isNotNullAndUndefined(headerVal) &&
        isNotNullAndUndefined(headerVal.value)
      ) {
        headerText = headerVal.value;
        return <span className="e-headertext">{headerText}</span>;
      }
    }
    return <span className="e-headertext">{fieldValue}</span>;
  };

  private customColumnWidthTemplate = (item: QueryAttributeJM) => {
    let widthVal = "";
    const gridColumnWidths: GridColumnWidth[] = this.props.gridColumnWidth;
    let widthValue: GridColumnWidth;
    if (
      isNotNullAndUndefined(gridColumnWidths) &&
      isNotNullAndUndefined(item)
    ) {
      widthValue = gridColumnWidths.find((x) => x.field === item.PropertyName);
      if (
        isNotNullAndUndefined(widthValue) &&
        isNotNullAndUndefined(widthValue.width)
      ) {
        //widthVal = widthValue.width;
      } else {
        //widthVal = "150";
      }
    } else {
      widthVal = "150";
    }
    return widthVal;
  };

  private customMaxColumnWidthTemplate = (item: QueryAttributeJM) => {
    let widthVal = "";
    const gridColumnWidths: GridColumnWidth[] = this.props.gridColumnWidth;
    let widthValue: GridColumnWidth;
    if (
      isNotNullAndUndefined(gridColumnWidths) &&
      isNotNullAndUndefined(item)
    ) {
      widthValue = gridColumnWidths.find((x) => x.field === item.PropertyName);
      if (
        isNotNullAndUndefined(widthValue) &&
        isNotNullAndUndefined(widthValue.width)
      ) {
        widthVal = widthValue.width;
      } else {
        // widthVal = "300";
      }
    } else {
      //widthVal = "300";
    }
    return widthVal;
  };

  toggleFilterBar = () => {
    let eFilterbar =
      this.grid &&
      this.grid.element &&
      this.grid.element.querySelector(".e-filterbar");
    if (
      isNotNullAndUndefined(eFilterbar) &&
      isNotNullAndUndefined(eFilterbar.classList)
    ) {
      if (this.state.filterBarVisibile === true) {
        eFilterbar.classList.remove("hidden");
      } else {
        eFilterbar.classList.add("hidden");
      }
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IProps & RAFDataListAdditionalProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    let resetState = false;
    const stateData = { ...this.state.data };
    const currData = this.props.data && { ...this.props.data.data };
    if (isNotNullAndUndefined(stateData) && isNullOrUndefined(currData)) {
      resetState = true;
    } else if (
      isNullOrUndefined(stateData) &&
      isNotNullAndUndefined(currData)
    ) {
      resetState = true;
    } else if (
      isNotNullAndUndefined(stateData) &&
      isNotNullAndUndefined(currData)
    ) {
      //if (stateData.count !== currData.count) {
      if (!deepEqual(stateData.result, currData.result)) {
        resetState = true;
      }
    }
    if (resetState === true) {
      if (this._isMounted) {
        this.setState({ data: { ...currData } }, () => { });
      }
    } else {
    }
    this.toggleFilterBar();
    //}
    if (prevProps.filterBarVisibile !== this.props.filterBarVisibile) {
      this.setState({
        filterBarVisibile: this.props.filterBarVisibile,
      });
    }
    this.changeGridContentHeight();
  }

  render() {
    return (
      <RolePermissionsContext.Consumer>
        {({ permissionValue }) => {
          if (
            isNotNullAndUndefined(permissionValue) &&
            isNotNullAndUndefined(this.props.checkPermissionSelection) &&
            isNotNullAndUndefined(this.props.checkPermissionEdit)
          ) {
            let allowEditing = hasPermissions(
              permissionValue,
              this.props.checkPermissionEdit
            );
            let allowSelection = hasPermissions(
              permissionValue,
              this.props.checkPermissionSelection
            );
            if (this.allowEditing === true) {
              this.allowEditing = allowEditing;
            }
            if (this.allowSelection === true) {
              this.allowSelection = allowSelection;
            }
          }
          return (
            <div className="h-100">
              <div className="hover-table-div h-100" id="divGridParent">
                {/*{showToolbar && <ToolbarComponent id='toolbar-actions' cssClass="toolbarhidden" clicked={toolbarActionClickHandler.bind(this)}>
                </ToolbarComponent>
                }*/}
                <div id="customSpinner"></div>
                {/* <TooltipComponent
                  ref={(t) => (this.tooltip = t)}
                  target=".e-rowcell"
                  beforeRender={this.beforeRender.bind(this)}
                  className="h-100"
                >
                </TooltipComponent> */}
                {this.renderGrid(this.props.fields)}
              </div>
            </div>
          );
        }}
      </RolePermissionsContext.Consumer>
    );
  }
}

export default RAFGridCC;
