import * as React from 'react';
import { Children, cloneElement, createContext, ReactNode } from 'react';
import { ContentType } from '../../constants/Common/Constants';
//import { useCheckMinimumRequiredProps } from '../../RAFComponents/helpers/checkMinimumRequiredProps';
import {
    RAFIdentifier, RAFSort
} from '../../RAFComponents/helpers/types';
import { combineFilters, deepEqual, getSaveRequest, isNotNullAndUndefined, isString } from '../../RAFComponents/helpers/utils';
import { ListServiceRequest } from '../../RAFComponents/models/Common/ListRequest';
import { RAFEntityBase } from '../../RAFComponents/models/Common/RAFEntityBase';
import { RAFCustomFilter } from '../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter';
import * as repositoryActions from '../../RAFComponents/store/actions/repositoryActions';
import { createInstance } from '../../RAFComponents/Utility/FormUtility';

type entityTypeOrUrl = (typeof RAFEntityBase) | RAFDataResult | string;

export interface ListControllerProps {
    //basePath: string;
    currentSort: RAFSort;
    currentFilter: RAFCustomFilter;
    //data: RecordMap<RecordType>;
    //defaultTitle?: string;
    //displayedFilters: any;
    //error?: any;
    //exporter?: Exporter | false;
    filterValues: any;
    //hasCreate: boolean;
    //hideFilter: (filterName: string) => void;
    //ids: Identifier[];
    loading: boolean;
    loaded: boolean;
    //onSelect: (ids: Identifier[]) => void;
    //onToggleItem: (id: Identifier) => void;
    //onUnselectItems: () => void;
    pageNumber: number;
    pageSize: number;
    //resource: string;
    selectedIds: RAFIdentifier[];
    //setFilters: (filters: any, displayedFilters: any) => void;
    setPage: (page: number) => void;
    setPerPage: (page: number) => void;
    setSort: (sort: string, order?: string) => void;
    //showFilter: (filterName: string, defaultValue: any) => void;
    //total: number;

    dataResult: RAFDataResult;
}

export const RAFDataListContext = createContext<ListControllerProps>({
    //basePath: null,
    currentSort: null,
    currentFilter: null,
    //data: null,
    //defaultTitle: null,
    //displayedFilters: null,
    filterValues: null,
    //hasCreate: null,
    //hideFilter: null,
    //ids: null,
    loaded: null,
    loading: null,
    //onSelect: null,
    //onToggleItem: null,
    //onUnselectItems: null,
    pageNumber: null,
    pageSize: null,
    //resource: null,
    selectedIds: null,
    //setFilters: null,
    setPage: null,
    setPerPage: null,
    setSort: null,
    //showFilter: null,
    //total: null,

    dataResult: null,
});

interface ListProps {
    children: ReactNode;
    //actions?: ReactElement | false;
    //aside?: ReactElement;
    //bulkActionButtons?: ReactElement | false;
    //classes?: any;
    //className?: string;
    //component?: FC<{ className?: string }>;
    //empty?: ReactElement | false;
    //exporter?: Exporter | false;
    //filter?: any;
    //filterDefaultValues?: any;
    //filters?: ReactElement;
    //pagination?: ReactElement | false;
    //perPage?: number;
    //sort?: Sort;
    //title?: string | ReactElement;


    rafDataSource: entityTypeOrUrl;
    saveUrl?: string;
    groupByUrl?: string;
    isRelated?: boolean;
    additionalFilter?: RAFCustomFilter;
    viewId?: string;
    viewName?: string;
}

export class RAFDataResult {
    result?: Object[];
    count?: number;
}

function instanceOfRAFDataResult(object: any): object is RAFDataResult {
    return 'result' in object;
}

export interface RAFDataListAdditionalProps {
    goToPage?: (pageNumber: number) => void;
    filtering?: (filter: RAFCustomFilter) => Promise<RAFDataResult>;
    paging?: (pageNumber: number, pageSize: number) => Promise<RAFDataResult>;
    sorting?: (sort: RAFSort) => Promise<RAFDataResult>;
    fetchData?: (pageNumber: number, pageSize: number, sort: RAFSort, filter: RAFCustomFilter) => Promise<RAFDataResult>;
    groupBy?: (pageNumber: number, pageSize: number, sort: RAFSort, filter: RAFCustomFilter) => Promise<RAFDataResult>;
    getDataForExport?: () => Promise<RAFDataResult>;
    dataSource?: RAFDataResult;
    saveData?: (rowData: Object, idField: string) => Promise<Object>;
    viewId?: string;
    viewName?: string;
}

export class RAFSFGridService {

    //public getData(url: string, pageNumber?: number, pageSize?: number, sort?: Sort[], objCustomFilter?: RAFCustomFilter): Promise<RAFDataResult> {
    public getData(url: string, viewId?: string, pageNumber?: number, pageSize?: number, sort?: RAFSort, objCustomFilter?: RAFCustomFilter, additionalFilter?: RAFCustomFilter, viewName?: string): Promise<RAFDataResult> {

        //url = url + (!IsNullOrWhiteSpace(viewId) ? ("/" + viewId) : "");

        //const listRequest: ListRequest = new ListRequest();
        const listRequest: ListServiceRequest = new ListServiceRequest();
        listRequest.ViewUID = viewId;
        listRequest.ViewName = viewName;
        listRequest.Take = isNotNullAndUndefined(pageSize) ? pageSize : 10;
        listRequest.Skip = ((isNotNullAndUndefined(pageNumber) ? pageNumber : 1) - 1) * (isNotNullAndUndefined(pageSize) ? pageSize : 10);
        //listRequest.CustomFilter = objCustomFilter;
        if (isNotNullAndUndefined(objCustomFilter) && isNotNullAndUndefined(additionalFilter)) {
            listRequest.CustomFilter = combineFilters(objCustomFilter, additionalFilter);
        }
        else if (isNotNullAndUndefined(additionalFilter)) {
            listRequest.CustomFilter = additionalFilter;
        }
        else if (isNotNullAndUndefined(objCustomFilter)) {
            listRequest.CustomFilter = objCustomFilter;
        }

        let sortQuery: string[] = [];

        //if ((sort || []).length) {
        //    sortQuery = (sort).map((obj: Sort) => {
        //        return obj.order === 'descending' ? `${obj.field} desc` : obj.field;
        //    }).reverse();
        //}
        if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
            sortQuery.push(sort.order === 'descending' ? `${sort.field} desc` : sort.field);
        }
        listRequest.Sort = sortQuery;

        return repositoryActions.postDataAndGetResponse(url,
            listRequest,
            null, ContentType.applicationJson)
            .then((response) => {
                let gridData = response.data;
                if (isNotNullAndUndefined(gridData) && isNotNullAndUndefined(gridData.Entities)) {
                    return {
                        count: gridData.TotalCount,
                        result: gridData.Entities
                    };
                }
                else {
                    return {
                        count: undefined,
                        result: undefined
                    };
                }
            });
    }

};

class RAFDataList extends React.Component<ListProps> {
    //useCheckMinimumRequiredProps('List', ['children'], props);
    private rafSFGridService: RAFSFGridService = new RAFSFGridService();
    private url = "";
    private saveUrl = "";
    private groupByUrl = "";
    public viewId: string;
    public viewName: string;
    private _additionalFilter?: RAFCustomFilter;
    get additionalFilter(): RAFCustomFilter {
        return this._additionalFilter;
    }
    set additionalFilter(value: RAFCustomFilter) {
        this._additionalFilter = value;

    }
    private _allDataResult: RAFDataResult;
    get dataResult(): RAFDataResult {
        return this._allDataResult;
    }
    set dataResult(value: RAFDataResult) {
        this._allDataResult = value;

    }

    //const controllerProps = useListController(props);
    private currentRAFDataState: ListControllerProps = {
        //basePath: "",
        currentSort: { field: "", order: "" },
        currentFilter: {},
        //data: {},
        //defaultTitle: "",
        //displayedFilters: null,
        //error: null,
        //exporter?: Exporter | false;
        filterValues: null,
        //hasCreate: false,
        //hideFilter: null,
        //ids: [],
        loading: false,
        loaded: false,
        //onSelect: null,
        //onToggleItem: null,
        //onUnselectItems: null,
        pageNumber: 1,
        pageSize: 10,
        //resource: "",
        selectedIds: [],
        //setFilters: null,
        setPage: null,
        setPerPage: null,
        setSort: null,
        //showFilter: null,
        //total: 0,

        dataResult: null,
    };
    //const {
    //    //actions,
    //    //aside,
    //    //filters,
    //    //bulkActionButtons,
    //    //pagination,
    //    children,
    //    //className,
    //    //classes: classesOverride,
    //    //component: Content,
    //    //exporter = defaultExporter,
    //    //title,
    //    //empty,
    //    rafDataSource,
    //    ...rest
    //} = props;

    constructor(props: ListProps) {
        super(props);

        //console.log('constructor=', this.currentRAFDataState);
        if (isNotNullAndUndefined(props.rafDataSource)) {
            if (isString(props.rafDataSource)) {
                this.url = props.rafDataSource.toString();
            }
            else if (instanceOfRAFDataResult(props.rafDataSource)) {
                this.dataResult = props.rafDataSource;
                this._allDataResult = props.rafDataSource;
                this.currentRAFDataState.dataResult = props.rafDataSource;
            }
            else {
                let objInstance = createInstance(props.rafDataSource as (typeof RAFEntityBase));
                //objInstance.constructor.name; 
                this.url = objInstance.getListUrl();
                if (isNotNullAndUndefined(props.isRelated) && props.isRelated === true) {
                    this.url = objInstance.getRelatedListUrl();
                }
                this.saveUrl = objInstance.getSaveUrl();
                if (isNotNullAndUndefined(objInstance["getGroupByUrl"])) {
                    this.groupByUrl = objInstance.getGroupByUrl();
                }
            }
        }
        if (isNotNullAndUndefined(props.saveUrl)) {
            this.saveUrl = props.saveUrl;
        }
        if (isNotNullAndUndefined(props.groupByUrl)) {
            this.groupByUrl = props.groupByUrl;
        }
        if (isNotNullAndUndefined(props.additionalFilter)) {
            this.additionalFilter = props.additionalFilter;
        }
        if (isNotNullAndUndefined(props.viewName)) {
            this.viewName = props.viewName;
        }
        if (isNotNullAndUndefined(props.viewId)) {
            this.viewId = props.viewId;
        }
        //console.log('props.additionalFilter', props.additionalFilter);
    }

    goToPage = (pageNumber: number): Promise<RAFDataResult> => {
        //console.log('1=gotoPage', pageNumber);
        this.currentRAFDataState.pageNumber = pageNumber;

        if (isNotNullAndUndefined(this.url)) {
            return this.rafSFGridService.getData(this.url, this.viewId, this.currentRAFDataState.pageNumber, this.currentRAFDataState.pageSize, this.currentRAFDataState.currentSort, this.currentRAFDataState.currentFilter, this.additionalFilter, this.viewName).then((gridData) => {
                this.currentRAFDataState.dataResult = gridData;
                return gridData;
            });
        }
    };

    fetchData = (pageNumber: number, pageSize: number, sort?: RAFSort, filter?: RAFCustomFilter): Promise<RAFDataResult> => {
        //console.log('1=fetchData', pageNumber)
        this.currentRAFDataState.pageNumber = pageNumber;
        this.currentRAFDataState.pageSize = pageSize;
        this.currentRAFDataState.currentSort = sort;
        this.currentRAFDataState.currentFilter = filter;
        //console.log('this.additionalFilter', this.additionalFilter);

        if (isNotNullAndUndefined(this.url)) {
            return this.rafSFGridService.getData(this.url, this.viewId, pageNumber, pageSize, sort, filter, this.additionalFilter, this.viewName).then((gridData) => {
                this.currentRAFDataState.dataResult = gridData;
                return gridData;
            });
        }
    };

    groupBy = (pageNumber: number, pageSize: number, sort?: RAFSort, filter?: RAFCustomFilter): Promise<RAFDataResult> => {
        //console.log('1=groupBy', pageNumber)
        this.currentRAFDataState.pageNumber = pageNumber;
        this.currentRAFDataState.pageSize = pageSize;
        this.currentRAFDataState.currentSort = sort;
        this.currentRAFDataState.currentFilter = filter;
        //console.log('this.additionalFilter', this.additionalFilter);

        if (isNotNullAndUndefined(this.url)) {
            return this.rafSFGridService.getData(this.groupByUrl, this.viewId, pageNumber, pageSize, sort, filter, this.additionalFilter).then((gridData) => {
                this.currentRAFDataState.dataResult = gridData;
                return gridData;
            });
        }
    };

    filtering = (filter?: RAFCustomFilter): Promise<RAFDataResult> => {
        //console.log('1=filter', filter)
        this.currentRAFDataState.currentFilter = filter;

        if (isNotNullAndUndefined(this.url)) {
            return this.rafSFGridService.getData(this.url, this.viewId, this.currentRAFDataState.pageNumber, this.currentRAFDataState.pageSize, this.currentRAFDataState.currentSort, filter, this.additionalFilter, this.viewName).then((gridData) => {
                this.currentRAFDataState.dataResult = gridData;
                return gridData;
            });
        }
    };

    paging = (pageNumber: number, pageSize: number): Promise<RAFDataResult> => {
        //console.log('1=paging', pageNumber, pageSize);

        this.currentRAFDataState.pageNumber = pageNumber;
        this.currentRAFDataState.pageSize = pageSize;


        if (isNotNullAndUndefined(this.url)) {
            return this.rafSFGridService.getData(this.url, this.viewId, pageNumber, pageSize, this.currentRAFDataState.currentSort, this.currentRAFDataState.currentFilter, this.additionalFilter, this.viewName).then((gridData) => {
                //console.log('rafdatalist gridData', gridData);
                this.currentRAFDataState.dataResult = gridData;
                return gridData;
            });
        }
    };


    sorting = (sort?: RAFSort): Promise<RAFDataResult> => {
        //console.log('1=sorting', sort)
        this.currentRAFDataState.currentSort = sort;

        if (isNotNullAndUndefined(this.url)) {
            return this.rafSFGridService.getData(this.url, this.viewId, this.currentRAFDataState.pageNumber, this.currentRAFDataState.pageSize, sort, this.currentRAFDataState.currentFilter, this.additionalFilter, this.viewName).then((gridData) => {
                this.currentRAFDataState.dataResult = gridData;
                return gridData;
            });
        }
    };

    getDataForExport = (): Promise<RAFDataResult> => {
        //console.log('1=getDataForExport')

        if (isNotNullAndUndefined(this.url)) {
            return this.rafSFGridService.getData(this.url, this.viewId, 1, 0, this.currentRAFDataState.currentSort, this.currentRAFDataState.currentFilter, this.additionalFilter, this.viewName).then((gridData) => {
                return gridData;
            });
        }
    };

    saveData = (rowData: Object, idField: string): Promise<Object> => {
        //console.log('1=gotoPage', pageNumber);
        const saveUrl = this.saveUrl;

        return new Promise<Object>((resolve/*, reject*/) => {
            if (isNotNullAndUndefined(saveUrl)) {

                repositoryActions.postDataAndGetResponse(saveUrl, getSaveRequest(rowData, rowData[idField]), null, ContentType.applicationJson)
                    .then((response) => {
                        if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                            if (
                                response.data.EntityId !== null &&
                                response.data.EntityId !== undefined &&
                                response.data.EntityId !== ""
                            ) {
                                rowData[idField] = response.data.EntityId;
                                resolve(rowData);

                            } else if (
                                response.data.Error !== null &&
                                response.data.Error !== undefined &&
                                response.data.Error !== ""
                            ) {
                                //console.log("Error on save", response.data.Error);
                            }
                        }
                    });

            }
            else {
                resolve(rowData);

            }
        });
    };

    setData = (pageNumber: number, pageSize: number, sort?: RAFSort, filter?: RAFCustomFilter, gridData?: RAFDataResult) => {
        //console.log('1=fetchData', pageNumber)
        this.currentRAFDataState.pageNumber = pageNumber;
        this.currentRAFDataState.pageSize = pageSize;
        this.currentRAFDataState.currentSort = sort;
        this.currentRAFDataState.currentFilter = filter;
        //console.log('this.additionalFilter', this.additionalFilter);
        this.currentRAFDataState.dataResult = gridData;

    };

    componentDidUpdate(prevProps: ListProps/*, prevState*/) {
        if (!deepEqual(prevProps.additionalFilter, this.props.additionalFilter)) {
            this.additionalFilter = this.props.additionalFilter;
        }
    }


    render() {
        const {
            //actions,
            //aside,
            //filters,
            //bulkActionButtons,
            //pagination,
            children,
            //className,
            //classes: classesOverride,
            //component: Content,
            //exporter = defaultExporter,
            //title,
            //empty,
            //rafDataSource,
            ...rest
        } = this.props;

        return (
            <RAFDataListContext.Provider value={this.currentRAFDataState} >
                {children &&
                    // @ts-ignore-line
                    cloneElement(Children.only(children), {
                        ...rest,
                        goToPage: this.goToPage,
                        fetchData: this.fetchData,
                        groupBy: this.groupBy,
                        filtering: this.filtering,
                        sorting: this.sorting,
                        paging: this.paging,
                        getDataForExport: this.getDataForExport,
                        saveData: this.saveData,
                        viewId: this.viewId,
                        viewName: this.viewName,
                    })}
            </RAFDataListContext.Provider >
        );
    }
};

//RAFDataList.propTypes = {
//    // the props you can change
//    // @ts-ignore-line
//    //actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
//    //aside: PropTypes.element,
//    // @ts-ignore-line
//    //bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
//    children: PropTypes.node,
//    //classes: PropTypes.object,
//    //className: PropTypes.string,
//    //filter: PropTypes.object,
//    //filterDefaultValues: PropTypes.object,
//    //filters: PropTypes.element,
//    // @ts-ignore-line
//    //pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
//    //perPage: PropTypes.number.isRequired,
//    //@ts-ignore-line
//    //sort: PropTypes.shape({
//    //    field: PropTypes.string,
//    //    order: PropTypes.string,
//    //}),
//    //title: TitlePropType,
//    // the props managed by react-admin
//    //authProvider: PropTypes.func,
//    //hasCreate: PropTypes.bool,//.isRequired,
//    //hasEdit: PropTypes.bool,//.isRequired,
//    //hasList: PropTypes.bool,//.isRequired,
//    //hasShow: PropTypes.bool,//.isRequired,
//    //location: PropTypes.any,
//    //match: PropTypes.any,
//    //path: PropTypes.string,
//    //resource: PropTypes.string,//.isRequired,

//    rafDataSource: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.shape(RAFEntityBase)), PropTypes.string]).isRequired,

//    //url: PropTypes.string.isRequired,
//};

//RAFDataList.defaultProps = {
//filter: {},
//perPage: 10,
//};

export default RAFDataList;