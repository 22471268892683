import { PanelModel } from '@syncfusion/ej2-react-layouts';
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../helpers/AppHelper";
import { ContentType, StorageKey } from "../../constants/Common/Constants";
import { getSaveRequest, isNotNullAndUndefined, IsNullOrWhiteSpace, propertyOf } from "../helpers/utils";
import { DashboardJM, LayoutModel } from "../models/Common/DashboardJM";
import { ListServiceRequest } from '../models/Common/ListRequest';
import { ReportJM } from '../models/Common/ReportJM';
import { RAFCustomFilter, RAFCustomOperator } from '../RAFViewPanels/RAFFilterColumn/RAFCustomFilter';

export function RetrieveDashboardById(id) {
    return new Promise<DashboardJM>((resolve) => {
        repositoryActions.postDataAndGetResponse('Dashboard/Retrieve', { "EntityId": id }, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            })
            .catch((error) => error);
    });
}

export function saveToDashboard(dashboardJM: DashboardJM, dashboardId: string, moduleName: string) {
    return new Promise<DashboardJM>((resolve) => {
        repositoryActions.postDataAndGetResponse('Dashboard/Save', getSaveRequest(dashboardJM, dashboardId), null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.EntityId)) {
                    let dashboardRow: DashboardJM = dashboardJM;
                    dashboardRow.UID = response.data.EntityId;
                    removeSessionStorage(StorageKey.allDashboardView_modulename + moduleName, true);
                    removeSessionStorage(
                        StorageKey.allDashboardView_modulename + "All",
                        true
                    );
                    resolve(dashboardRow);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
    });
}

export function checkWidgetExist(refId?: string, dashboardJM?: DashboardJM) {
    return new Promise<boolean>((resolve) => {
        let panels = dashboardJM && dashboardJM.Layout.Panels;
        if (isNotNullAndUndefined(panels)) {
            let layout: LayoutModel[] = panels && panels;
            let index = layout && layout.findIndex(x => x.RefId === refId);
            if (index > -1) {
                resolve(true);
            }
            else {
                resolve(false);
            }
        }
        else {
            resolve(false);
        }

    }).catch((error) => error);
}

export function checkPanelExist(refId?: string, panels?: PanelModel[]) {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(panels)) {
            let index = panels && panels.findIndex(x => x['data-refID'] === refId);
            if (index > -1) {
                resolve(true);
            }
            else {
                resolve(false);
            }
        }
        else {
            resolve(false);
        }

    }).catch((error) => error);
}

export const getAllDashboardItems = () => {
    let url = `Dashboard/List`;
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    //listServiceRequest.ViewName = "all_dashboard";

    return new Promise<DashboardJM[]>((resolve) => {
        const allDashboardViewStorage = getSessionStorage(
            StorageKey.allDashboardView_modulename + "All",
            true
        );
        if (
            isNotNullAndUndefined(allDashboardViewStorage) &&
            !IsNullOrWhiteSpace(allDashboardViewStorage)
        ) {
            const dashboardList: DashboardJM[] = JSON.parse(allDashboardViewStorage);
            resolve(dashboardList);
        } else {
            return repositoryActions
                .postDataAndGetResponse(
                    url,
                    listServiceRequest,
                    null,
                    ContentType.applicationJson,
                    false
                )
                .then((response) => {
                    if (response.status === 500) {
                        resolve(null);
                    } else {
                        if (
                            isNotNullAndUndefined(response) &&
                            isNotNullAndUndefined(response.data) &&
                            isNotNullAndUndefined(response.data.Entities)
                        ) {
                            const dashboardList: DashboardJM[] = response.data.Entities;
                            if (dashboardList.length > 0) {
                                setSessionStorage(
                                    StorageKey.allDashboardView_modulename + "All",
                                    true,
                                    JSON.stringify(dashboardList)
                                );
                                resolve(dashboardList);
                            } else {
                                removeSessionStorage(
                                    StorageKey.allDashboardView_modulename + "All",
                                    true
                                );
                                resolve(null);
                            }
                        } else {
                            resolve(null);
                        }
                    }
                })
                .catch((error) => error);
        }
    });
};

export const getDashboardListByModuleName = (moduleName?: string) => {
    let url = `Dashboard/List`;
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(moduleName);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<ReportJM>("Entity");
    relatedFilter.Rules.push(filter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    //listServiceRequest.ViewName = "all_dashboard";

    return new Promise<DashboardJM[]>((resolve) => {
        if (isNotNullAndUndefined(moduleName)) {
            const allDashboardViewStorage = getSessionStorage(
                StorageKey.allDashboardView_modulename + moduleName,
                true
            );
            if (
                isNotNullAndUndefined(allDashboardViewStorage) &&
                !IsNullOrWhiteSpace(allDashboardViewStorage)
            ) {
                const dashboardList: DashboardJM[] = JSON.parse(
                    allDashboardViewStorage
                );
                resolve(dashboardList);
            } else {
                return repositoryActions
                    .postDataAndGetResponse(
                        url,
                        listServiceRequest,
                        null,
                        ContentType.applicationJson,
                        false
                    )
                    .then((response) => {
                        if (response.status === 500) {
                            resolve(null);
                        } else {
                            if (
                                isNotNullAndUndefined(response) &&
                                isNotNullAndUndefined(response.data) &&
                                isNotNullAndUndefined(response.data.Entities)
                            ) {
                                const dashboardList: DashboardJM[] = response.data.Entities;
                                if (dashboardList.length > 0) {
                                    setSessionStorage(
                                        StorageKey.allDashboardView_modulename + moduleName,
                                        true,
                                        JSON.stringify(dashboardList)
                                    );
                                    resolve(dashboardList);
                                } else {
                                    removeSessionStorage(
                                        StorageKey.allDashboardView_modulename + moduleName,
                                        true
                                    );
                                    resolve(null);
                                }
                            } else {
                                resolve(null);
                            }
                        }
                    })
                    .catch((error) => error);
            }
        } else {
            resolve(null);
        }
    });
};
